import React,{useState,useEffect} from 'react'
import ItMenu from '../ItMenu'
import { useLocation, useNavigate } from 'react-router-dom';
import toast from 'react-hot-toast';
import { ButtonFilledAutoWidth, ButtonOutlinedAutoWidth } from '../../../components/button';
import { Modal,Select } from 'antd';
import { IconButton } from '@mui/material'
import moment from 'moment';
import { AiOutlineEdit,AiOutlineDelete } from 'react-icons/ai';
import { Tooltip } from '@mui/material';
import {BsArrowRepeat} from 'react-icons/bs';
import {FiChevronRight,FiChevronLeft} from 'react-icons/fi';
import { DeleteInventoryOptionService, GetInventoryOptionService } from '../../../services/ITServices/InventoryOption/InventoryOptionServices';
import { GetFeatureOptionService } from '../../../services/ITServices/FeatureOption/FeatureOptionServices';
import { HiOutlineDuplicate } from "react-icons/hi";

function InventoryOptionList() {


  const {pathname}  = useLocation()
  const path = pathname.split('/')[pathname.split('/').length - 1]

  const [data,setdata] = useState([])
  const [selecteddata,setselecteddata] = useState({})
  const [modal, setModal] = useState(false);
  const [search,setsearch] = useState({text:'',stage:'',status:''})

  const [stages,setstages] = useState([])
  const [status,setstatus] = useState([])

  const [type,settype] = useState('')
  const [page,setpage] = useState(1)

  const navigate = useNavigate()

  useEffect(()=>{
    getstages()
    getstatus()
  },[])

  useEffect(()=>{
    if(path == 'laptop'){
      settype("Laptop")
      setpage(1)
      getdata("Laptop",1)
    }
    if(path == 'desktop'){
      settype("Desktop")
      setpage(1)
      getdata("Desktop",1)
    }
    if(path == 'cpu'){
      settype("CPU")
      setpage(1)
      getdata("CPU",1)
    }
    if(path == 'mobile'){
      settype("Mobile")
      setpage(1)
      getdata("Mobile",1)
    }
    if(path == 'mouse'){
      settype("Mouse")
      setpage(1)
      getdata("Mouse",1)
    }
    if(path == 'keyboard'){
      settype("Keyboard")
      setpage(1)
      getdata("Keyboard",1)
    }
    if(path == 'hard_disk'){
      settype("HardDisk")
      setpage(1)
      getdata("HardDisk",1)
    }
    if(path == 'cable'){
      settype("Cable")
      setpage(1)
      getdata("Cable",1)
    }
    if(path == 'laptop_charger'){
      settype("Laptop Charger")
      setpage(1)
      getdata("Laptop Charger",1)
    }
    if(path == 'mobile_charger'){
      settype("Mobile Charger")
      setpage(1)
      getdata("Mobile Charger",1)
    }
    if(path == 'pendrive'){
      settype("Pendrive")
      setpage(1)
      getdata("Pendrive",1)
    }
  },[path,type])


  useEffect(()=>{
    getdata()
  },[page,type])


  async function getstages() {
    const response = await GetFeatureOptionService(1,'','Device Stage')
    let arr = []
    let d = response?.data?.datas 
    d.forEach((d1)=>{
      arr.push({label:d1?.name,value:d1?._id})
    })
    setstages(arr)
  }

  async function getstatus() {
    const response = await GetFeatureOptionService(1,'','Device Status')
    let arr = []
    let d = response?.data?.datas 
    d.forEach((d1)=>{
      arr.push({label:d1?.name,value:d1?._id})
    })
    setstatus(arr)
  }




   function handlechange(v,type){
      if(type == 'stage'){
        let singleStage = stages?.find((e)=>e?.value === v)
        setsearch({...search,stage:singleStage})
      }
      if(type == 'status'){
        let singleStage = status?.find((e)=>e?.value === v)
        setsearch({...search,status:singleStage})
      }
   }

    async function getdata(type1,page1){
        const response = await GetInventoryOptionService(page1 !== undefined ? page1 : page,search?.text,type1 !== undefined ? type1 : type,search?.stage?.value !== undefined ? search?.stage?.value : '',search?.status?.value !== undefined ? search?.status?.value : '')
        setdata(response.data)
    }  

    async function deletedata(){
        const response = await DeleteInventoryOptionService(selecteddata._id)
        if(response.status === 200){
            setModal(false)
            toast.success("Deleted Successfully")
            getdata()
        }
    }

    async function resetfunc() {
      setpage(1)
      setsearch({text:'',stage:'',status:''})
      const response = await GetInventoryOptionService(1,'',type,'','')
      setdata(response.data)
    }

    async function applyfilterfunction() {
      setpage(1)
      const response = await GetInventoryOptionService(1,search?.text,type,search?.stage?.value !== undefined ? search?.stage?.value : '',search?.status?.value !== undefined ? search?.status?.value : '')
      setdata(response.data)
    }

  return (
    <div className='flex mx-0 box-border  max-h-screen overflow-y-scroll overflow-x-hidden'>
        <Modal
        keepMounted
        open={modal}
        onClose={()=>setModal(false)}
        width={300}
        footer={false}
        closable={false}
       
      >
        <div >
          <h6 className="font-bold text-[15px] text-center mb-2 w-full">Are you sure?</h6>
          <h6 className='bg-slate-100 text-center text-[12px] p-1.5 font-[400]'>After deleting you cannot retrieve it back before deleting check once whether you have used it in some data</h6>
          <div className='flex justify-end mt-3 '>
            {/* <div className='mr-1 w-full'> */}
            <ButtonOutlinedAutoWidth btnName={'Cancel'} onClick={()=>setModal(false)} />
            {/* </div> */}
            <div  className='ml-2'>
            <ButtonFilledAutoWidth btnName={'Confirm'} onClick={()=>deletedata()}  /> 
            </div>
          </div>
        </div>
        </Modal>
        

        <div>
            <ItMenu />
        </div>
        <div className='px-4 w-[100%]'>
        <div >
        <div className='pt-5'>
        <div className="flex justify-between align-center items-center border-b pb-2 ">
        <span className="font-black text-[14px]">Total {type} ({data?.pagination?.total})</span>


        <div className='flex items-center'>
              <div className='flex items-center text-[12px] mr-2'>
                       
                       
                       

                        <h6 className='mr-2 font-[600]'>{page == 1 ? data?.datas?.length > 0 ? 1 : 0 : (page - 1) * data?.pagination?.limit } - {data?.pagination?.limit} of {data?.pagination?.total} </h6>
                        <IconButton onClick={resetfunc}><BsArrowRepeat size={16} /></IconButton>

                        <div>
                        <IconButton onClick={()=>{page > 1 ? setpage(page-1) : console.log('')}}><FiChevronLeft className={`${page === 1 ? 'opacity-50' : 'opacity-100'}`}  size={16} /></IconButton>
                        <IconButton onClick={()=>{ page < data?.pagination?.totalPages  ? setpage(page+1) : console.log('')}}><FiChevronRight className={`${(data?.pagination?.totalPages === page || data?.datas?.length === 0)  ? 'opacity-50' : 'opacity-100'}`}  size={16} /></IconButton>

                        </div>
                    </div>


                    {/* <DatePicker size='small' style={{fontSize:'11px'}} ampm={false} placeholder='From Date' className='text-[11px] py-[2.6px]  w-28 border-slate-300 mr-2'  value={search?.from_date} onChange={(v,v1) => {console.log('v na',new Date(v).toLocaleDateString());setsearch({...search,from_date:v,from_date1:v1})}} /> 

                    <DatePicker size='small' style={{fontSize:'11px'}} ampm={false} placeholder='To Date' className='text-[11px] py-[2.6px]  w-28 border-slate-300 mr-2'  value={search?.to_date} onChange={(v,v1) => {console.log('v na',new Date(v).toLocaleDateString());setsearch({...search,to_date:v,to_date1:v1})}} /> 

                     */}
          
          <div className='border border-slate-300 rounded-md mr-[4px] py-[2px]'>
            <Select
                placeholder='Select Status'
                bordered={false}
                size='small'
                defaultValue={search?.status !== '' ? search?.status : null}
                value={search?.status !== '' ? search?.status : null}
                style={{ width: 110 }}
                onChange={(v)=>handlechange(v,'status')}
                options={status}
            />
          </div>

          <div className='border border-slate-300 rounded-md mr-[4px] py-[2px]'>
            <Select
                placeholder='Select Stage'
                bordered={false}
                size='small'
                defaultValue={search?.stage !== '' ? search?.stage : null}
                value={search?.stage !== '' ? search?.stage : null}
                style={{ width: 110 }}
                onChange={(v)=>handlechange(v,'stage')}
                options={stages}
            />
          </div>

                    <div className='mr-2 flex'>
                        

                    <input  id="search_text" placeholder='Search text' type='text' value={search.text} onChange={(e)=>setsearch({...search,text:e.target.value})} className='border py-[3px] focus:ring-0 focus:outline-0 text-[14px]  w-28 px-1 rounded-md border-slate-300' />
                     
                 

                   
                    </div>    

                  
                <ButtonOutlinedAutoWidth btnName="Add Filter" onClick={()=>applyfilterfunction(1)} /> 
               
                <div className='ml-2'>
                <ButtonFilledAutoWidth btnName="Add Data" onClick={()=>navigate('create')}/> 
                </div>
                    </div>

        
        </div>
        </div>

        {data?.datas?.length === 0 &&
        <div className='grid place-items-center mt-20  items-center justify-center'>
        <img src={'https://fidecrmfiles.s3.amazonaws.com/NoDataFound1.png'} className='w-40 h-40 object-contain' /> 
        <h6 className='font-bold text-[14px] -mt-10'>No data found</h6>
        <h6 className='font-[500] w-[70%] text-center text-slate-700 text-[12.5px] -mt-2'>Oops we couldn't find any data added based on your current page option please click on add the data button to see list here.</h6>
      </div>
       }


        {data?.datas?.length > 0 &&
        <div className='grid grid-cols-6 gap-1 mt-2'>
            {data?.datas?.map((d,i)=>(
            <div  key={d?.id}  className='border items-center relative justify-between px-2 py-1 border-b'>
              <div className='h-[100px] flex items-center justify-center bg-slate-100'>
                {![null,undefined,'','null','undefined']?.includes(d?.image) && <img src={`${process.env.REACT_APP_AWS_IMAGE_URL}${d?.image}`} alt="No Img" className='h-[80px] bg-slate-100' />}
                </div>
                <h6 className='text-[13px] font-[500] '>{d.name}</h6>
                <h6 className='text-[12px] font-[500] '>ASSET ID : <span className='font-[800]'>{d.asset_id}</span></h6>
                <h6 className='text-[10px] font-[500] '>Type : <span className='font-[800]'>{d.type}</span></h6>
                <h6 className='text-[10px] font-[500] bg-slate-100 mt-1 p-1'>Created At : <span className='text-[10px] font-[800]'>{moment(d.createdAt).format('LLL')}</span> </h6>
                <div className='absolute right-0 p-1 bg-white top-0 flex'>
                <Tooltip title="Duplicate" >
                 <span><HiOutlineDuplicate size={12} className="cursor-pointer" onClick={()=>{navigate('create',{state:d})}}/></span>
                </Tooltip>
                <Tooltip title="Edit" >
                 <span><AiOutlineEdit size={12} className="cursor-pointer ml-2" onClick={()=>{navigate('edit',{state:d})}}/></span>
                </Tooltip>
                <Tooltip title="Delete">
                 <span><AiOutlineDelete size={12} className='ml-2 cursor-pointer'  onClick={()=>{setselecteddata(d);setModal(true)}}/></span>
                </Tooltip>
                </div>
            </div>
            ))}
        </div>}
          </div>
        </div>
    </div>
  )
}

export default InventoryOptionList