import React, { useEffect, useState } from 'react'
import GoBack from '../../components/back/GoBack'
import { TextInput } from '../../components/input'
import { TextAreaInput1 } from '../../components/input'
import { ButtonFilledAutoWidth, ButtonOutlinedAutoWidth } from '../../components/button'
import { CreateInsentiveBasic, CreateInsentivePeriodList, GetInsentivePeriodList, UpdateInsentiveBasic } from '../../services/AdminServicesfile/InsentiveServices'
import { toast } from 'react-hot-toast'
import { useLocation,useNavigate } from 'react-router-dom'
import {AiOutlineEdit,AiOutlineDelete,AiOutlinePlusSquare} from 'react-icons/ai';
import {IoMdClose} from 'react-icons/io'
import SettingsMenu from './SettingsMenu'
import { Modal } from 'antd'

function InsentiveRequirmentCE(){

    const navigator = useNavigate()

    const [dateArr,setdateArr] = useState([])
    const [dateArrErr,setdateArrErr] = useState([])

    const [data,setdata] = useState({year:'',hod_percentage:'',manager_percentage:'',employee_percentage:'',hod_note:'',manager_note:'',employee_note:'',incentive_start:'',incentive_end:'',incentive_period:'',is_active:false,show_incentive_to_user:false,hod_percentage_based_employee:'',manager_percentage_based_employee:'',employee_percentage_based_employee:'',recieve_payment_within:'',minimum_transaction:'',tds:''})
    const [error,seterror] = useState({year:'',hod_percentage:'',manager_percentage:'',employee_percentage:'',hod_note:'',manager_note:'',employee_note:'',incentive_start:'',incentive_end:'',incentive_period:'',is_active:'',show_incentive_to_user:'',hod_percentage_based_employee:'',manager_percentage_based_employee:'',employee_percentage_based_employee:'',recieve_payment_within:'',minimum_transaction:'',tds:''})
    
    const [period_Data,setperiod_Data] = useState({from:'',to:'',incentive_percentage:''})
    const [period_DataErr,setperiod_DataErr] = useState({from:'',to:'',incentive_percentage:''})

    const [period,setperiod] = useState([])

    const [periodAddBool,setperiodAddBool] = useState(false)
    
    const {pathname,state} = useLocation()

    const path = pathname.split('/')[pathname.split('/').length - 1] 

     

    useEffect(()=>{
       if(path === 'edit'){
        setdata({year:state?.year,hod_percentage:state?.hod_percentage,manager_percentage:state?.manager_percentage,employee_percentage:state.employee_percentage,hod_note:state?.hod_note,manager_note:state?.manager_note,employee_note:state?.employee_note,incentive_start:JSON.stringify(state?.incentive_start)?.slice(1,11),incentive_end:JSON.stringify(state?.incentive_end)?.slice(1,11),incentive_period:state?.incentive_period,is_active:state.is_active,hod_percentage_based_employee:state?.hod_percentage_based_employee,manager_percentage_based_employee:state?.manager_percentage_based_employee,employee_percentage_based_employee:state?.employee_percentage_based_employee,recieve_payment_within:state?.recieve_payment_within,minimum_transaction:state?.minimum_transaction,show_incentive_to_user:(state?.show_incentive_to_user !== null && state?.show_incentive_to_user !== undefined) ? state?.show_incentive_to_user : false,tds:state?.tds !== undefined ? state?.tds : 0})
        setperiod(state?.incentive_requirment)
        let arr = []
        state?.incentive_date_time_period?.forEach((e,i)=>{
            arr[i] = e?.slice(0,10)
        })
        setdateArr(arr)

       }else{
            setdata({year:'',hod_percentage:'',manager_percentage:'',employee_percentage:'',hod_note:'',manager_note:'',employee_note:'',incentive_start:'',incentive_end:'',incentive_period:'',is_active:false,show_incentive_to_user:false,hod_percentage_based_employee:'',manager_percentage_based_employee:'',employee_percentage_based_employee:'',recieve_payment_within:'',minimum_transaction:'',tds:''})
            seterror({year:'',hod_percentage:'',manager_percentage:'',employee_percentage:'',hod_note:'',manager_note:'',employee_note:'',incentive_start:'',incentive_end:'',incentive_period:'',is_active:'',show_incentive_to_user:'',hod_percentage_based_employee:'',manager_percentage_based_employee:'',employee_percentage_based_employee:'',recieve_payment_within:'',minimum_transaction:'',tds:''})
       }
    },[])

    function handlechange(e){
        setdata({...data,[e.target.name] : e.target.value})
        seterror({...error,[e.target.name] : ''})
    }



    async function submitData(){
        if(data.year === ''){
            seterror({...error,year:'The year field is required'})
        }else if(data.hod_percentage  === ''){
            seterror({...error,hod_percentage:'The Hod percentage field is required'})
        }else if(data.manager_percentage  === ''){
            seterror({...error,manager_percentage:'The Manager percentage field is required'})
        }else if(data.employee_percentage  === ''){
            seterror({...error,employee_percentage:'The Employee percentage field is required'})
        }else if(data.hod_percentage_based_employee  === ''){
            seterror({...error,hod_percentage_based_employee:'The Hod Employee percentage field is required'})
        }else if(data.manager_percentage_based_employee  === ''){
            seterror({...error,manager_percentage_based_employee:'The Manager Employee percentage field is required'})
        }else if(data.employee_percentage_based_employee  === ''){
            seterror({...error,employee_percentage_based_employee:'The Employee percentage field is required'})
        }else if(data.employee_percentage  === ''){
            seterror({...error,employee_percentage:'The Employee percentage field is required'})
        }else if(data.hod_note  === ''){
            seterror({...error,hod_note:'The Hod Note field is required'})
        }else if(data.manager_note  === ''){
            seterror({...error,manager_note:'The Manger Note field is required'})
        }else if(data.employee_note  === ''){
            seterror({...error,employee_note:'The Employee Note field is required'})
        }else if(data.incentive_start  === ''){
            seterror({...error,incentive_start:'The Incentive start field is required'})
        }else if(data.incentive_end  === ''){
            seterror({...error,incentive_end:'The Incentive end field is required'})
        }else if(data.incentive_period  === ''){
            seterror({...error,incentive_period:'The Incentive period field is required'})
        }else if(data.recieve_payment_within  === ''){
            seterror({...error,recieve_payment_within:'The recieve payment  field is required'})
        }else if(data.minimum_transaction  === ''){
            seterror({...error,minimum_transaction:'The Minimum field is required'})
        }else{
            for(let i = 0; i< Number(data?.incentive_period);i++){
                if(dateArr[i] === undefined){
                    let error = [...dateArrErr] 
                    error[i] = 'This Field is required'
                    setdateArrErr(error)
                }else if( i === (Number(data?.incentive_period) - 1) && (dateArr[i] !== undefined) && !dateArr.includes('') && !dateArr.includes(null) && !dateArr.includes(undefined)){
                    data['incentive_date_time_period'] = dateArr
                    if(path === 'edit'){
                        const response = await UpdateInsentiveBasic(state?._id,data)
                        if(response.status === 200){
                            toast.success('Updated successfully')
                            resetform()
                            setTimeout(() => { 
                                navigator(-1)
                            }, 1500);
                        }
                    }else{
                        const response = await CreateInsentiveBasic(data)
                        if(response.status === 201){
                            toast.success('Created successfully')
                            resetform()
                        }else if(response.status === 422){
                            seterror({...error,year:response?.data?.message})
                        }
                    }
        
                }else{
                } 
            }
        }
    } 


    function resetform(){
        setdata({year:'',hod_percentage:'',manager_percentage:'',employee_percentage:'',hod_note:'',manager_note:'',employee_note:'',incentive_start:'',incentive_end:'',incentive_period:'',is_active:false,show_incentive_to_user:false,hod_percentage_based_employee:'',manager_percentage_based_employee:'',employee_percentage_based_employee:'',recieve_payment_within:'',minimum_transaction:'',tds:''})
        seterror({year:'',hod_percentage:'',manager_percentage:'',employee_percentage:'',hod_note:'',manager_note:'',employee_note:'',incentive_start:'',incentive_end:'',incentive_period:'',is_active:'',show_incentive_to_user:'',hod_percentage_based_employee:'',manager_percentage_based_employee:'',employee_percentage_based_employee:'',recieve_payment_within:'',minimum_transaction:'',tds:''})
        setdateArr([])
        setdateArrErr([])
    }

    function handleDateChange(i,e){
        let old_date = [...dateArr]
        let old_error = [...dateArrErr]
        old_date[i] = e.target.value
        old_error[i] = ''
        setdateArr(old_date)
        setdateArrErr(old_error)
    }

    async function insentiveperiod(){
        if(!period_Data.from){
            setperiod_DataErr({...period_DataErr,from:'This Field is required*'})
        }else if(!period_Data.to){
            setperiod_DataErr({...period_DataErr,to:'This Field is required*'})
        }else if(!period_Data.incentive_percentage){
            setperiod_DataErr({...period_DataErr,incentive_percentage:'This Field is required*'})
        }else{ 
              if(period_Data.index !== undefined){
                   let data = [...period]
                   data[period_Data.index] = {from:period_Data.from,to:period_Data.to,incentive_percentage:period_Data.incentive_percentage} 
                   setperiod(data)
                   setperiodAddBool(false)
                   setperiod_Data({from:'',to:'',incentive_percentage:''})
                   setperiod_DataErr({from:'',to:'',incentive_percentage:''})
              }else{ 
                const exist = period.filter(d=> d.from == period_Data.from && d.to == period_Data.to)
                if(exist.length > 0){
                    setperiod_DataErr({...period_DataErr,from:'This Field should be unique*',to:'This Field should be unique*'})
                }else{
                    setperiodAddBool(false)
                    setperiod([...period,period_Data])
                    setperiod_Data({from:'',to:'',incentive_percentage:''})
                    setperiod_DataErr({from:'',to:'',incentive_percentage:''})
                }
             } 
        }
    }

    async function getinsentiveperiodlist(){
        const response = await GetInsentivePeriodList(state?._id)
        setperiod(response.data.data)
    }

    async function insentiveperiodsubmit(){
        let datas = []
        period?.forEach((p)=>{
            datas.push({...p,incentive_requirment:state?._id})
        })
        const data_send = {
            'incentive_requirment':state?._id,
            'data': datas
        }

        // console.log('data_send',data_send)
        const response = await CreateInsentivePeriodList(data_send)
        if(response.status === 200){
            toast.success(response?.data?.data)
            setperiodAddBool(false)
            getinsentiveperiodlist()
        }
    }


    function deleteItemIndex(i){
        let data =[...period]
        data.splice(i,1)
        setperiod(data)
    }


  return (
    <div className='min-h-screen h-screen max-h-screen overflow-hidden'>


        <Modal 
        open={periodAddBool}
        closable={false}
        footer={false}
        width={300}>
            <div className='relative'>
            <IoMdClose className="absolute -right-2 -top-2" onClick={()=>setperiodAddBool(!periodAddBool)} />
        <h6 className='font-[800] text-[14px]'>Add /Edit Incentive Period</h6>

        <TextInput label="From (Days)" name="from" value={period_Data?.from} error={period_DataErr?.from} handlechange={(e)=>{setperiod_Data({...period_Data,from:e.target.value});setperiod_DataErr({...period_DataErr,from:''})}} />
        <TextInput label="To (Days)" name="to" value={period_Data?.to} error={period_DataErr?.to} handlechange={(e)=>{setperiod_Data({...period_Data,to:e.target.value});setperiod_DataErr({...period_DataErr,to:''})}} />
        <TextInput label="Incentive Percentage" name="incentive_percentage" value={period_Data?.incentive_percentage} error={period_DataErr?.incentive_percentage} handlechange={(e)=>{setperiod_Data({...period_Data,incentive_percentage:e.target.value});setperiod_DataErr({...period_DataErr,incentive_percentage:''})}} />
        
        <div className='mt-4'>
        <ButtonOutlinedAutoWidth btnName="SAVE" onClick={insentiveperiod}/>
        </div>
            </div>

        </Modal>
       
       <div className='block sm:flex'>
       <SettingsMenu /> 
       <div className='ml-4 w-full h-screen overflow-y-scroll '>

        <div className='flex  '>  
            <div className='w-72 border-r pr-4 mr-4'>
              <GoBack />


                <h6 className='font-[800] text-[14px]'>Add/Edit the Insentive Basic Requirment</h6>
                <h6 className='text-[11px] bg-slate-100  p-1 my-1'>Use the below form to create or update the insentive basic requirment for the team</h6>
                <TextInput 
                    
                    label={'Year'}  
                    variant="standard"
                    name="year"
                    type="text"
                    value={data.year}
                    error={error.year}
                    handlechange={handlechange}
                />
                
        
                <h6 className='text-[12px] font-[600] mb-0.5 mt-4' >{'INCENTIVE PERCENTAGE'}</h6>

                <div className='flex items-center'>
                    <div className='mr-2'>
                        <TextInput 
                            
                            label={'HOD'}  
                            variant="standard"
                            name="hod_percentage"
                            type="text"
                            value={data.hod_percentage}
                            error={error.hod_percentage}
                            handlechange={handlechange}
                        />
                    </div>

                    <div className='mr-2'>
                        <TextInput 
                            
                            label={'Manager'}  
                            variant="standard"
                            name="manager_percentage"
                            type="text"
                            value={data.manager_percentage}
                            error={error.manager_percentage}
                            handlechange={handlechange}
                        />
                    </div>

                    <div>
                        <TextInput 
                            
                            label={'Employee'}  
                            variant="standard"
                            name="employee_percentage"
                            type="text"
                            value={data.employee_percentage}
                            error={error.employee_percentage}
                            handlechange={handlechange}
                        />
                    </div>


                </div>   

                <h6 className='text-[12px] font-[600] mb-0.5 mt-4' >{'EMPLOYEE PERCENTAGE'}</h6>

                <div className='flex items-center'>
                    <div className='mr-2'>
                        <TextInput 
                            
                            label={'HOD'}  
                            variant="standard"
                            name="hod_percentage_based_employee"
                            type="text"
                            value={data.hod_percentage_based_employee}
                            error={error.hod_percentage_based_employee}
                            handlechange={handlechange}
                        />
                    </div>

                    <div className='mr-2'>
                        <TextInput 
                            
                            label={'Manager'}  
                            variant="standard"
                            name="manager_percentage_based_employee"
                            type="text"
                            value={data.manager_percentage_based_employee}
                            error={error.manager_percentage_based_employee}
                            handlechange={handlechange}
                        />
                    </div>

                    <div>
                        <TextInput 
                            
                            label={'Employee'}  
                            variant="standard"
                            name="employee_percentage_based_employee"
                            type="text"
                            value={data.employee_percentage_based_employee}
                            error={error.employee_percentage_based_employee}
                            handlechange={handlechange}
                        />
                    </div>


                </div>   

                    <TextAreaInput1 value={data?.hod_note}  error={error?.hod_note} handlechange={handlechange} name="hod_note" label="Hod Note"   />
                    <TextAreaInput1 value={data?.manager_note}  error={error?.manager_note} handlechange={handlechange} name="manager_note" label="Manager Note"   />
                    <TextAreaInput1 value={data?.employee_note}  error={error?.employee_note} handlechange={handlechange} name="employee_note" label="Employee Note"   />

                    <TextInput 
                        label={'Period'}  
                        variant="standard"
                        name="incentive_period"
                        type="text"
                        value={data.incentive_period}
                        error={error.incentive_period}
                        handlechange={handlechange}
                    />

                    <TextInput 
                        label={'Recieve Payment Within'}  
                        variant="standard"
                        name="recieve_payment_within"
                        type="text"
                        value={data.recieve_payment_within}
                        error={error.recieve_payment_within}
                        handlechange={handlechange}
                    />

                    <TextInput 
                        label={'Minimum Transaction'}  
                        variant="standard"
                        name="minimum_transaction"
                        type="text"
                        value={data.minimum_transaction}
                        error={error.minimum_transaction}
                        handlechange={handlechange}
                    />

                <div className='flex'>
                <div className="w-full ml-1 mt-1">     
                    <h6 className='text-[12px] font-semibold mb-1 mt-1'>Incentive Start </h6>
                    <input  type="date" placeholder="" value={data?.incentive_start} onChange={(e)=>{setdata({...data,incentive_start:e.target.value});seterror({...error,incentive_start:''})}}  id="first_name" className ="bg-white border border-slate-200 text-gray-900 text-[11px] rounded  block w-full p-1.5 py-2 outline-0 " style={{background:'white'}} />
                    {(error?.incentive_start !== '' || error?.incentive_start !== '')  && <h6 className='text-red-600 text-[10px]'>{error?.incentive_start}</h6>}
                </div>

                <div className="w-full ml-1 mt-1">     
                    <h6 className='text-[12px] font-semibold mb-1 mt-1'>Incentive End </h6>
                    <input  type="date" placeholder="" value={data?.incentive_end} onChange={(e)=>{setdata({...data,incentive_end:e.target.value});seterror({...error,incentive_start:''})}}  id="first_name" className ="bg-white border border-slate-200 text-gray-900 text-[11px] rounded  block w-full p-1.5 py-2 outline-0 " style={{background:'white'}} />
                    {(error?.incentive_end !== '' || error?.incentive_end !== '')  && <h6 className='text-red-600 text-[10px]'>{error?.incentive_end}</h6>}
                </div>
                </div>
                {/* flex w-[500px]  overflow-scroll h-24 */}
                
                <div className=''>
                {
                data?.incentive_period !== '' &&  new Array(Number(data?.incentive_period)).fill('')?.map((e,i)=>(
                        <div key={i} className=" ml-1 mt-1">     
                            <h6 className='text-[12px] font-semibold mb-1 mt-1'>Period Stop ({i+1}) </h6>
                            <input  type="date" placeholder="" value={dateArr[i]} onChange={(e)=>handleDateChange(i,e)}  id="first_name" className ="bg-white border border-slate-200 text-gray-900 text-[11px] rounded  block w-full p-1.5 py-2 outline-0 " style={{background:'white'}} />
                            {(dateArrErr[i] !== '' && dateArrErr[i] !== undefined) && <h6 className='text-red-600 text-[10px]'>{dateArrErr[i]}</h6>}

                        </div>
                    ))
                }
                </div>


                <div className='flex items-center mt-2 mr-2 ml-1'>
                <input type={'checkbox'} checked={data?.is_active} onChange={()=>setdata({...data,is_active:!data.is_active})} /> 
                <h6 className="text-[13px] font-[600] ml-2">Is Active</h6> 
                </div>  

                <div className='flex items-center mt-2 mb-2 mr-2 ml-1'>
                <input type={'checkbox'} checked={data?.show_incentive_to_user} onChange={()=>setdata({...data,show_incentive_to_user:!data.show_incentive_to_user})} /> 
                <h6 className="text-[13px] font-[600] ml-2">Show Incentive For User</h6> 
                </div>  

                <TextInput 
                        label={'TDS'}  
                        variant="standard"
                        name="tds"
                        type="number"
                        value={data.tds}
                        error={error.tds}
                        handlechange={handlechange}
                    />


                <div className='flex w-full align-end items-end my-2 pb-5'>
                    <div className="mr-2">
                    <ButtonOutlinedAutoWidth btnName={'Cancel'} onClick={()=>navigator(-1)} />
                    </div>
                    <ButtonFilledAutoWidth btnName={'Save'} onClick={submitData} />
                </div>

            </div>

            {path === 'edit' &&
            <div className='w-72 mt-[54px]'>
                <h6 className='font-[800] text-[14px]'>Add/Edit the Insentive Periods</h6>
                <h6 className='text-[11px] bg-slate-100  p-1 my-1'>Use the form below to create a time intervals for the payment for the current insentive requirment</h6>
                 
                
                <div className='flex mt-2 justify-end' onClick={()=>setperiodAddBool(!periodAddBool)}>
                <AiOutlinePlusSquare  /> 
                </div>
                <div className='flex items-center bg-gray-100 border mt-2'>
                    <h6 className='text-[13px] min-w-[25%] p-1 w-[25%] max-w-[25%] border-r'>From<span className='mx-1 text-[10px]'>(Days)</span></h6>
                    <h6 className='text-[13px] min-w-[25%] p-1 w-[25%] max-w-[25%] border-r'>To<span className='mx-1 text-[10px]'>(Days)</span></h6>
                    <h6 className='text-[13px] min-w-[25%] p-1 w-[25%] max-w-[25%] border-r'>Percent</h6>
                    <h6 className='text-[13px] min-w-[25%] p-1 w-[25%] max-w-[25%]'>Action</h6>
                </div>
                
                {period?.map((p,i)=>(
                <div key={i} className='flex items-center border-l border-r border-b'>
                    <h6 className='text-[13px] min-w-[25%] p-1 w-[25%] max-w-[25%] border-r'>{p?.from}</h6>
                    <h6 className='text-[13px] min-w-[25%] p-1 w-[25%] max-w-[25%] border-r'>{p?.to}</h6>
                    <h6 className='text-[13px] min-w-[25%] p-1 w-[25%] max-w-[25%]  border-r'>{p?.incentive_percentage}</h6>
                    <h6 className='text-[13px] flex items-center min-w-[25%] p-1 w-[25%] max-w-[25%] cursor-pointer'><AiOutlineEdit className='mr-2' onClick={()=>{setperiodAddBool(true);setperiod_Data({...p,index:i})}} /> <AiOutlineDelete onClick={()=>deleteItemIndex(i)} />  </h6>
                </div>))}

              
                <div className='mt-4 flex justify-end'>
                   <ButtonOutlinedAutoWidth btnName="Submit" onClick={insentiveperiodsubmit}  />
                </div>    
            </div>}
       </div> 


       {/* {periodAddBool &&
       <div className='absolute right-0 top-0 border-l min-h-screen bg-white p-2 pt-10'>
        <IoMdClose className="absolute -right-6 top-2" onClick={()=>setperiodAddBool(!periodAddBool)} />
        <h6 className='font-[800] text-[14px]'>Add /Edit Incentive Period</h6>

        <TextInput label="From (Days)" name="from" value={period_Data?.from} error={period_DataErr?.from} handlechange={(e)=>{setperiod_Data({...period_Data,from:e.target.value});setperiod_DataErr({...period_DataErr,from:''})}} />
        <TextInput label="To (Days)" name="to" value={period_Data?.to} error={period_DataErr?.to} handlechange={(e)=>{setperiod_Data({...period_Data,to:e.target.value});setperiod_DataErr({...period_DataErr,to:''})}} />
        <TextInput label="Incentive Percentage" name="incentive_percentage" value={period_Data?.incentive_percentage} error={period_DataErr?.incentive_percentage} handlechange={(e)=>{setperiod_Data({...period_Data,incentive_percentage:e.target.value});setperiod_DataErr({...period_DataErr,incentive_percentage:''})}} />
        
        <div className='mt-4'>
        <ButtonOutlinedAutoWidth btnName="SAVE" onClick={insentiveperiod}/>
        </div>
       </div>} */}

    </div>
    </div>  
    </div>
  )
}

export default InsentiveRequirmentCE