import React,{useState,useEffect} from 'react'
import { useLocation,useNavigate } from 'react-router-dom'
import moment from 'moment';
import ReactApexChart from 'react-apexcharts';
import DOMPurify from "dompurify";
import {
    IconButton,Tooltip
  } from "@mui/material";
import {Drawer, Modal} from 'antd';
import {AiOutlineMail,AiOutlinePhone,AiOutlineCalendar,AiOutlineCloudDownload} from 'react-icons/ai';
import {HiLogout} from 'react-icons/hi';
import {CiStickyNote} from 'react-icons/ci';
import {CopyToClipboard} from 'react-copy-to-clipboard';
import { GetDepartmentService } from '../../services/DepartmentServices';
import { Select } from 'antd';
import { GetStageService } from '../../services/StageServices';
import { DatePicker } from 'antd'
import DashboardMenu from '../dashboard/DashboardMenu';
import {BsArrowRepeat} from 'react-icons/bs';
import {FiChevronRight,FiChevronLeft} from 'react-icons/fi';
import { AiOutlineEdit } from 'react-icons/ai';
import fileDownload from "js-file-download";
import { toast } from 'react-hot-toast';
import {IoMdArrowForward} from 'react-icons/io';
import axios from 'axios';
import { IoFilterCircleOutline } from "react-icons/io5";
import { IoClose } from "react-icons/io5";

import { GetAllAssignedLeadsCalendarService, GetAllAssignedLeadsFilterService, GetAllAssignedLeadsGraphService, GetAllAssignedLeadsService, GetAllFidelitusLeadsIndivisualDateService } from '../../services/AssignLeadServices';
import { AiOutlineTable } from "react-icons/ai";
import { VscGraph } from "react-icons/vsc";
import { CiCalendarDate } from "react-icons/ci";
import { BiFilterAlt } from "react-icons/bi";
import { RxReset } from "react-icons/rx";
import { HiChevronLeft,HiChevronRight } from "react-icons/hi";
import { GetSearchService } from '../../services/AuthServices';
import { useSelector } from 'react-redux';

function FidelitusLeads() {

  const location = useLocation();
   const roles = useSelector(state=>state.Auth.roles)


  const navigate = useNavigate();

  const [leads,setleads]  = useState([])
  const [graphData,setgraphData]  = useState([])
  const [calendarData,setcalendarData]  = useState({})
  const [fidelitusIndivisualDateBasedData,setfidelitusIndivisualDateBasedData]  = useState({})

  const [selectedDate,setselectedDate] = useState('')
  const [selectedDateData,setselectedDateData] = useState([])

  const monthNames = [
    "January", "February", "March", "April", "May", "June",
    "July", "August", "September", "October", "November", "December"
  ];

  const [modal,setmodal] = useState(false)
  const [modal1,setmodal1] = useState(false)

  const [selected_department,setselected_department] = useState(null)
  const [created_department,setcreated_department] = useState(null)
  const [selected_user,setselected_user] = useState(null)
  const [selected_stage,setselected_stage] = useState(null)
  const [selected_lead,setselected_lead] = useState({})
  const [attachment,setattachment] = useState([])
  const [note,setnote] = useState([])
  const [preview,setpreview] = useState(false)

  function getDaysInMonth(month, year) {
    return new Date(year, month + 1, 0).getDate();
  }


  const [calendar_report,setcalendar_report] = useState({year:'',month:'',no_of_days:'',start_date_index:'',from_date:'',to_date:''})


  const [departments,setdepartments] = useState([])
  const [users,setusers] = useState([])
  const [stages,setstages] = useState([])
  const [page,setpage] = useState(1)

  const [step,setstep] = useState(1)

  const [filter_drawer,setfilter_drawer] = useState(false)

  const [search,setsearch] = useState({text:'',from_date:'',from_date1:'',to_date:'',to_date1:'',activate:false,zone:''})

  const weekDays = ['Sunday','Monday','Tuesday','Wednesday','Thursday','Friday','Saturday']

  const stage = selected_lead?.stage?.name === 'Hold' ? [{name:'contacts',value:'Contact',index:0},{name:'hold',value:'Hold',index:1},{name:'options_shared',value:'Options',index:2},{name:'inspections',value:'Inspection',index:3},{name:'loi_agreement',value:'Agreement',index:4},{name:'invoice_raised',value:'Invoice',index:5}] : [{name:'contacts',value:'Contact',index:0},{name:'options_shared',value:'Options',index:1},{name:'inspections',value:'Inspection',index:2},{name:'loi_agreement',value:'Agreement',index:3},{name:'invoice_raised',value:'Invoice',index:4}]


  const [chartOptions1,setchartOptions1] = useState({
    series: [],

    chart: {
      toolbar: {
        show: false
      }
    },
    colors:['#3289a8', '#87c9ff','#ffc88c', '#ffa7a1','#8cffc2'],
    plotOptions: {
      
      bar: {
        horizontal: false,
        columnWidth: '55%',
        endingShape: 'rounded',
        dataLabels: {
          position: 'top', // top, center, bottom
        },
        
      },
    },
    dataLabels: {
      enabled: false
    },
    grid:{
      show: true,
      borderColor: '#fafafa',
    },
  
    stroke: {
      // show: true,
      width: 1,
      
      curve: 'smooth',
      colors: ['transparent']
    },
    xaxis: {
      categories:    ['Contact', 'Options', 'Inspection', 'Agreement', 'Invoice', 'Hold'],
    },
    yaxis: {
      title: {
        text: 'Count'
      }
    },
    fill: {
      opacity: 1
    },
    
    tooltip: {
      y: {
        formatter: function (val) {
          return  val
        }
      }
    },
    fill:['#027cd1']
  })



  
  useEffect(()=>{
      if(step == 1){
        getleads(1)
      }else if(step == 2){
        getleadsgraph()
      }else if(step == 3){
        getcalendarData()
      }
  },[location.pathname,filter_drawer,step])

  useEffect(()=>{
    if(search?.activate){
      applyfilterfunction(page)
    }else{
      if(step == 1){
          getleads(page)
      }else if(step == 2){
          getleadsgraph()
      }else if(step == 3){
          getcalendarData()
      }
    }  
  },[page,selected_user,created_department,selected_department,selected_stage])

  useEffect(()=>{
      getdepartment()
      getstage()

      let month = new Date().getMonth()
      let year = new Date().getFullYear()
      let days = getDaysInMonth(month,year)

      let firstDayOfMonth = new Date(year,month,1)
      let dayOfWeek = firstDayOfMonth.getDay()

      let from_date = `${year}-${JSON.stringify(month)?.length == 1 ? `0${month+1}` : month+1}-01`
      let to_date = `${year}-${JSON.stringify(month)?.length == 1 ? `0${month+1}` : month+1}-${days}`

      setcalendar_report({...calendar_report,year:year,month:month,no_of_days:days,start_date_index:dayOfWeek,from_date,to_date})

    
  },[])

  async function getstage(){
      const response = await GetStageService('Lead_Stage')
      let arr = []
      response?.data?.data?.datas?.forEach((d)=>{
          if(d?.name !== 'Calls' && d?.name !== 'Walk-Ins' && d?.name !== 'Meetings' && d?.name !== 'Property-Visit'){
            arr.push({label:d?.name,value:d?._id})
          }
      })
      setstages(arr)
  }


  async function getdepartment(){
      const response = await GetDepartmentService();
      let arr = []
      response?.data?.datas.forEach((d)=>{
          if(d?.department_name !== 'Finance Team'){
              arr.push({label:d?.department_name,value:d?.id})
          }
      })
      setdepartments(arr)
  }


  async function applyfilterfunction(page){
      setsearch({...search,activate:true})
      // if(step === 1){
      // const response = await GetAllAssignedLeadsFilterService(
      //   selected_department?.value,
      //   created_department?.value,
      //   selected_user?.value,
      //   page,
      //   search?.text,
      //   search?.from_date1,
      //   search?.to_date1,
      //   search?.zone !== '' ? search?.zone?.value : ''
      // )
      // setleads(response.data)   
      if(step == 1){
        getleads(1)
      }else if(step == 2){
          getleadsgraph()
      }else if(step == 3){
          getcalendarData()
      }
    // }
  }

  async function getleads(page){
      const response =  await GetAllAssignedLeadsService(selected_department?.value !== undefined ? selected_department?.value : null,selected_user?.value !== undefined ? selected_user?.value : null,created_department?.value !== undefined ? created_department?.value : null,page,search?.from_date1,search?.to_date1);
      setleads(response?.data)
  }

  async function getleadsgraph(){
      const response =  await GetAllAssignedLeadsGraphService(selected_department?.value !== undefined ? selected_department?.value : null,selected_user?.value !== undefined ? selected_user?.value : null,created_department?.value !== undefined ? created_department?.value : null,search?.from_date1,search?.to_date1);

      let d = response?.data?.datas

      let transactionData = d?.find((f)=>f?.department?.department_name === 'Transaction Team')
      let projectData = d?.find((f)=>f?.department?.department_name === 'Project Team')
      let hrData = d?.find((f)=>f?.department?.department_name === 'HR Team')
      let fmsData = d?.find((f)=>f?.department?.department_name === 'FMS Team')
      let mdData = d?.find((f)=>f?.department?.department_name === 'Corp Team')
      let galleryData = d?.find((f)=>f?.department?.department_name === 'Fidelitus Gallery')
      let shilpaFoundationData = d?.find((f)=>f?.department?.department_name === 'Shilpa Foundation')
      let mediaData = d?.find((f)=>f?.department?.department_name === 'Media Team')

      let arr = []
      let leadsData = []
      let pendingData = []
      let progressData = []
      let rejectedData = []
      let convertedData = []

      let chartOptions = []

      if(transactionData !== undefined && transactionData !== null){
        chartOptions.push('Transaction Team')
        leadsData.push(transactionData.leads)
        pendingData.push(transactionData.pending)
        progressData.push(transactionData.progress)
        rejectedData.push(transactionData.rejected)
        convertedData.push(transactionData.converted)
        arr.push(transactionData)
      }else{
        chartOptions.push('Transaction Team')
        leadsData.push(0)
        pendingData.push(0)
        progressData.push(0)
        rejectedData.push(0)
        convertedData.push(0)
      }

      if(projectData !== undefined && projectData !== null){
        chartOptions.push('Project Team')
        leadsData.push(projectData.leads)
        pendingData.push(projectData.pending)
        progressData.push(projectData.progress)
        rejectedData.push(projectData.rejected)
        convertedData.push(projectData.converted)
        arr.push(projectData)
      }else{
        leadsData.push(0)
        pendingData.push(0)
        progressData.push(0)
        rejectedData.push(0)
        convertedData.push(0)
        chartOptions.push('Project Team')
      }

      if(hrData !== undefined && hrData !== null){
        leadsData.push(hrData.leads)
        pendingData.push(hrData.pending)
        progressData.push(hrData.progress)
        rejectedData.push(hrData.rejected)
        convertedData.push(hrData.converted)
        chartOptions.push('HR Team')
        arr.push(hrData)
      }else{
        leadsData.push(0)
        pendingData.push(0)
        progressData.push(0)
        rejectedData.push(0)
        convertedData.push(0)
        chartOptions.push('HR Team')
      }

      if(fmsData !== undefined && fmsData !== null){
        leadsData.push(fmsData.leads)
        pendingData.push(fmsData.pending)
        progressData.push(fmsData.progress)
        rejectedData.push(fmsData.rejected)
        convertedData.push(fmsData.converted)
        chartOptions.push('FMS Team')
        arr.push(fmsData)
      }else{
        leadsData.push(0)
        pendingData.push(0)
        progressData.push(0)
        rejectedData.push(0)
        convertedData.push(0)
        chartOptions.push('FMS Team')
      }

      if(mdData !== undefined && mdData !== null){
        leadsData.push(mdData.leads)
        pendingData.push(mdData.pending)
        progressData.push(mdData.progress)
        rejectedData.push(mdData.rejected)
        convertedData.push(mdData.converted)
        chartOptions.push('Corp Team')
        arr.push(mdData)
      }else{
        leadsData.push(0)
        pendingData.push(0)
        progressData.push(0)
        rejectedData.push(0)
        convertedData.push(0)
        chartOptions.push('Corp Team')
      }

      if(galleryData !== undefined && galleryData !== null){
        leadsData.push(galleryData.leads)
        pendingData.push(galleryData.pending)
        progressData.push(galleryData.progress)
        rejectedData.push(galleryData.rejected)
        convertedData.push(galleryData.converted)
        chartOptions.push('Fidelitus Gallery')
        arr.push(galleryData)
      }else{
        leadsData.push(0)
        pendingData.push(0)
        progressData.push(0)
        rejectedData.push(0)
        convertedData.push(0)
        chartOptions.push('Fidelitus Gallery')
      }

      if(shilpaFoundationData !== undefined && shilpaFoundationData !== null){
        leadsData.push(shilpaFoundationData.leads)
        pendingData.push(shilpaFoundationData.pending)
        progressData.push(shilpaFoundationData.progress)
        rejectedData.push(shilpaFoundationData.rejected)
        convertedData.push(shilpaFoundationData.converted)
        chartOptions.push('Shilpa Foundation')
        arr.push(shilpaFoundationData)
      }else{
        leadsData.push(0)
        pendingData.push(0)
        progressData.push(0)
        rejectedData.push(0)
        convertedData.push(0)
        chartOptions.push('Shilpa Foundation')
      }

      if(mediaData !== undefined && mediaData !== null){
        leadsData.push(mediaData.leads)
        pendingData.push(mediaData.pending)
        progressData.push(mediaData.progress)
        rejectedData.push(mediaData.rejected)
        convertedData.push(mediaData.converted)
        chartOptions.push('Media Team')
        arr.push(mediaData)
      }else{
        leadsData.push(0)
        pendingData.push(0)
        progressData.push(0)
        rejectedData.push(0)
        convertedData.push(0)
        chartOptions.push('Media Team')
      }

      let series = [
        {name:"Leads",data:leadsData},
        {name:"Pending",data:pendingData},
        {name:"Progress",data:progressData},
        {name:"Rejected",data:rejectedData},
        {name:"Converted",data:convertedData},
      ]

      setchartOptions1({
        ...chartOptions1,
        series,
        xaxis:{
          categories:chartOptions
        }
      })

      setgraphData(arr)
  }

  async function getcalendarData(){
      const response =  await GetAllAssignedLeadsCalendarService(selected_department?.value !== undefined ? selected_department?.value : null,calendar_report?.from_date,calendar_report?.to_date,created_department?.value !== undefined ? created_department?.value : null,selected_user?.value !== undefined ? selected_user?.value : null);
      let d = response?.data
      setcalendarData(d)
  }

  async function handlechange(val,type){

  
      if(type === 'department'){
            let department = departments.find(d=>d.value === val)
            if(selected_department !== null && selected_department.value !== department.value){
                setpage(1)
                setselected_department(department)
            }else{
                setpage(1)
                setselected_department(department)
            }
      }else if(type === 'department1'){
          let department = departments.find(d=>d.value === val)
          if(created_department !== null && created_department.value !== department.value){
              setpage(1)
              setcreated_department(department)
          }else{
              setpage(1)
              setcreated_department(department)
          }
      }else if(type === 'user'){
          setpage(1)
          setselected_user(users.find(d=>d.value === val))
      }else if(type === 'stages'){
          setpage(1)
          setselected_stage(stages.find((d)=>d.value === val))
      }else if(type === 'zone'){
          setpage(1)
          setsearch({...search,zone:options.find((d)=>d.value === val)})
      }
  }

   const resetfilter=async()=>{
        setselected_user(null)
        setselected_stage(null)
        setselected_department(null)
        setcreated_department(null)
        setselected_user(null)
        setsearch({text:'',from_date:'',from_date1:'',to_date:'',to_date1:'',activate:false,zone:''})
        setfilter_drawer(false)
        setpage(1)
        if(step == 1){
          const response =  await GetAllAssignedLeadsService(selected_department?.value !== undefined ? selected_department?.value : null,selected_user?.value !== undefined ? selected_user?.value : null,created_department?.value !== undefined ? created_department?.value : null,page,search?.from_date1,search?.to_date1);
          setleads(response?.data)
        }else if(step == 2){
          const response =  await GetAllAssignedLeadsGraphService(selected_department?.value !== undefined ? selected_department?.value : null,selected_user?.value !== undefined ? selected_user?.value : null,created_department?.value !== undefined ? created_department?.value : null,search?.from_date1,search?.to_date1);

          let d = response?.data?.datas

          let transactionData = d?.find((f)=>f?.department?.department_name === 'Transaction Team')
          let projectData = d?.find((f)=>f?.department?.department_name === 'Project Team')
          let hrData = d?.find((f)=>f?.department?.department_name === 'HR Team')
          let fmsData = d?.find((f)=>f?.department?.department_name === 'FMS Team')
          let mdData = d?.find((f)=>f?.department?.department_name === 'Corp Team')
          let galleryData = d?.find((f)=>f?.department?.department_name === 'Fidelitus Gallery')
          let shilpaFoundationData = d?.find((f)=>f?.department?.department_name === 'Shilpa Foundation')
          let mediaData = d?.find((f)=>f?.department?.department_name === 'Media Team')

          let arr = []
          let leadsData = []
          let pendingData = []
          let rejectedData = []
          let convertedData = []

          let chartOptions = []

          if(transactionData !== undefined && transactionData !== null){
            chartOptions.push('Transaction Team')
            leadsData.push(transactionData.leads)
            pendingData.push(transactionData.pending)
            rejectedData.push(transactionData.rejected)
            convertedData.push(transactionData.converted)
            arr.push(transactionData)
          }else{
            chartOptions.push('Transaction Team')
            leadsData.push(0)
            pendingData.push(0)
            rejectedData.push(0)
            convertedData.push(0)
          }

          if(projectData !== undefined && projectData !== null){
            chartOptions.push('Project Team')
            leadsData.push(projectData.leads)
            pendingData.push(projectData.pending)
            rejectedData.push(projectData.rejected)
            convertedData.push(projectData.converted)
            arr.push(projectData)
          }else{
            leadsData.push(0)
            pendingData.push(0)
            rejectedData.push(0)
            convertedData.push(0)
            chartOptions.push('Project Team')
          }

          if(hrData !== undefined && hrData !== null){
            leadsData.push(hrData.leads)
            pendingData.push(hrData.pending)
            rejectedData.push(hrData.rejected)
            convertedData.push(hrData.converted)
            chartOptions.push('HR Team')
            arr.push(hrData)
          }else{
            leadsData.push(0)
            pendingData.push(0)
            rejectedData.push(0)
            convertedData.push(0)
            chartOptions.push('HR Team')
          }

          if(fmsData !== undefined && fmsData !== null){
            leadsData.push(fmsData.leads)
            pendingData.push(fmsData.pending)
            rejectedData.push(fmsData.rejected)
            convertedData.push(fmsData.converted)
            chartOptions.push('FMS Team')
            arr.push(fmsData)
          }else{
            leadsData.push(0)
            pendingData.push(0)
            rejectedData.push(0)
            convertedData.push(0)
            chartOptions.push('FMS Team')
          }

          if(mdData !== undefined && mdData !== null){
            leadsData.push(mdData.leads)
            pendingData.push(mdData.pending)
            rejectedData.push(mdData.rejected)
            convertedData.push(mdData.converted)
            chartOptions.push('Corp Team')
            arr.push(mdData)
          }else{
            leadsData.push(0)
            pendingData.push(0)
            rejectedData.push(0)
            convertedData.push(0)
            chartOptions.push('Corp Team')
          }

          if(galleryData !== undefined && galleryData !== null){
            leadsData.push(galleryData.leads)
            pendingData.push(galleryData.pending)
            rejectedData.push(galleryData.rejected)
            convertedData.push(galleryData.converted)
            chartOptions.push('Fidelitus Gallery')
            arr.push(galleryData)
          }else{
            leadsData.push(0)
            pendingData.push(0)
            rejectedData.push(0)
            convertedData.push(0)
            chartOptions.push('Fidelitus Gallery')
          }

          if(shilpaFoundationData !== undefined && shilpaFoundationData !== null){
            leadsData.push(shilpaFoundationData.leads)
            pendingData.push(shilpaFoundationData.pending)
            rejectedData.push(shilpaFoundationData.rejected)
            convertedData.push(shilpaFoundationData.converted)
            chartOptions.push('Shilpa Foundation')
            arr.push(shilpaFoundationData)
          }else{
            leadsData.push(0)
            pendingData.push(0)
            rejectedData.push(0)
            convertedData.push(0)
            chartOptions.push('Shilpa Foundation')
          }

          if(mediaData !== undefined && mediaData !== null){
            leadsData.push(mediaData.leads)
            pendingData.push(mediaData.pending)
            rejectedData.push(mediaData.rejected)
            convertedData.push(mediaData.converted)
            chartOptions.push('Media Team')
            arr.push(mediaData)
          }else{
            leadsData.push(0)
            pendingData.push(0)
            rejectedData.push(0)
            convertedData.push(0)
            chartOptions.push('Media Team')
          }

          let series = [
            {name:"Leads",data:leadsData},
            {name:"Pending",data:pendingData},
            {name:"Rejected",data:rejectedData},
            {name:"Converted",data:convertedData},
          ]

          setchartOptions1({
            ...chartOptions1,
            series,
            xaxis:{
              categories:chartOptions
            }
          })

          setgraphData(arr)
        }else if(step == 3){
          const response =  await GetAllAssignedLeadsCalendarService(selected_department?.value !== undefined ? selected_department?.value : null,calendar_report?.from_date,calendar_report?.to_date,created_department?.value !== undefined ? created_department?.value : null,selected_user?.value !== undefined ? selected_user?.value : null);
          let d = response?.data
          setcalendarData(d)
        }


  }

  function returnfilename(v){
        let string = v?.split("/")[v?.split("/")?.length - 1]
        return string;
  }
    
  const handleDownload = (url, filename) => {
        axios.get(url, {
            responseType: 'blob',
        })
            .then((res) => {
                fileDownload(res.data, filename)
                toast.success("Attachment Downloaded") 
            })
  }
    
  function getFileSizeString(fileSizeInBytes) {
        var i = -1;
        var byteUnits = [' kB', ' MB', ' GB', ' TB', 'PB', 'EB', 'ZB', 'YB'];
        do {
        fileSizeInBytes /= 1024;
        i++;
        } while (fileSizeInBytes > 1024);
        return Math.max(fileSizeInBytes, 0.1).toFixed(1).slice(0,4) + byteUnits[i];
  }

  const options = [{label:'North',value:'North'},{label:'South',value:'South'},{label:'East',value:'East'},{label:'West',value:'West'}]
  
  function returnCount(v){
    let formatDate = `${calendar_report.year}-${JSON.stringify(calendar_report?.month)?.length == 1 ? `0${calendar_report.month+1}` : calendar_report.month+1}-${JSON.stringify(v).length == 1 ? `0${v}` : v}`
    let filterData = calendarData?.datas?.filter((f)=>f?.date == formatDate)
    return filterData?.length
  }

  function returnData(v){
    let formatDate = `${calendar_report.year}-${JSON.stringify(calendar_report?.month)?.length == 1 ? `0${calendar_report.month+1}` : calendar_report.month+1}-${JSON.stringify(v).length == 1 ? `0${v}` : v}`
    let filterData = calendarData?.datas?.filter((f)=>f?.date == formatDate)
    let returnString = `` 
    filterData?.forEach((d)=>(
      returnString += `<li style="background:#334155;vorder-radius:4px;padding:0px 4px;color:white;margin-bottom:2px">${d?.department.department_name} - ${d?.leads}</li>`
    ))
    return returnString !== '' && `<ul>${returnString}</ul>`
  }

  async function resetmonth(v){
      if(v === 0){

        let month = calendar_report.month 

        if(month > 0){
          month = month - 1
          let year = calendar_report.year 
          
          let days = getDaysInMonth(month,year)
  
          let firstDayOfMonth = new Date(year,month,1)
          let dayOfWeek = firstDayOfMonth.getDay()

          let from_date = `${year}-${JSON.stringify(month)?.length == 1 ? `0${month+1}` : month+1}-01`
          let to_date = `${year}-${JSON.stringify(month)?.length == 1 ? `0${month+1}` : month+1}-${days-1}`


          const response =  await GetAllAssignedLeadsCalendarService(selected_department?.value !== undefined ? selected_department?.value : null,from_date,to_date);
          let d = response?.data
          setcalendarData(d)
          setcalendar_report({...calendar_report,year:year,month:month,no_of_days:days,start_date_index:dayOfWeek,from_date,to_date})
  
        }else{
          let year = calendar_report.year - 1
          let month = 11
          let days = getDaysInMonth(month,year)

  
          let firstDayOfMonth = new Date(year,month,1)
          let dayOfWeek = firstDayOfMonth.getDay()
         
          let from_date = `${year}-${JSON.stringify(month)?.length == 1 ? `0${month+1}` : month+1}-01`
          let to_date = `${year}-${JSON.stringify(month)?.length == 1 ? `0${month+1}` : month+1}-${days-1}`


          const response =  await GetAllAssignedLeadsCalendarService(selected_department?.value !== undefined ? selected_department?.value : null,from_date,to_date);
          let d = response?.data
          setcalendarData(d)
          setcalendar_report({...calendar_report,year:year,month:month,no_of_days:days,start_date_index:dayOfWeek,from_date,to_date})
  
        }

      }else{
        let month = calendar_report.month 

        if(month < 11){
          month = month + 1
          let year = calendar_report.year
          let days = getDaysInMonth(month,year)

  
          let firstDayOfMonth = new Date(year,month,1)
          let dayOfWeek = firstDayOfMonth.getDay()

          let from_date = `${year}-${JSON.stringify(month)?.length == 1 ? `0${month+1}` : month+1}-01`
          let to_date = `${year}-${JSON.stringify(month)?.length == 1 ? `0${month+1}` : month+1}-${days-1}`


          const response =  await GetAllAssignedLeadsCalendarService(selected_department?.value !== undefined ? selected_department?.value : null,from_date,to_date);
          let d = response?.data
          setcalendarData(d)
          setcalendar_report({...calendar_report,year:year,month:month,no_of_days:days,start_date_index:dayOfWeek,from_date,to_date})
  
        }else{
          let year = calendar_report.year + 1
          let month = 0
          let days = getDaysInMonth(month,year)

  
          let firstDayOfMonth = new Date(year,month,1)
          let dayOfWeek = firstDayOfMonth.getDay()
          let from_date = `${year}-${JSON.stringify(month)?.length == 1 ? `0${month+1}` : month+1}-01`
          let to_date = `${year}-${JSON.stringify(month)?.length == 1 ? `0${month+1}` : month+1}-${days-1}`


          const response =  await GetAllAssignedLeadsCalendarService(selected_department?.value !== undefined ? selected_department?.value : null,from_date,to_date);
          let d = response?.data
          setcalendarData(d)
          setcalendar_report({...calendar_report,year:year,month:month,no_of_days:days,start_date_index:dayOfWeek,from_date,to_date})
  
        }
      }
  }

  async function searchUser(v){
    const response =  await GetSearchService(v,1)
          let arr = []
          let d = response?.data?.datas
          d.forEach((d1)=>{
            arr.push({label:d1.name,value:d1?._id})
          })
          setusers(arr)
          return arr
  }

  async function openSingleModalData(v){

    let year = calendar_report.year
    let month = calendar_report.month 
    let date = `${year}-${JSON.stringify(month)?.length == 1 ? `0${month + 1}` : month + 1}-${JSON.stringify(v)?.length == 1 ? `0${v}` : v}`

    let filterData = calendarData?.datas?.filter((f)=>f?.date == date)



    setselectedDate(date)
    setselectedDateData(filterData)
    setmodal1(true)

    const response = await GetAllFidelitusLeadsIndivisualDateService(
      selected_department?.value !== undefined ? selected_department?.value : null,
      selected_user?.value !== undefined ? selected_user?.value : null,
      created_department?.value !== undefined ? created_department?.value : null,
      date,
      date)
    setfidelitusIndivisualDateBasedData(response?.data)


  }

  async function openVerticalData(){
    navigate('/dashboard/fidelitus_vertical_leads')
  }




  return (
    <div style={{width:'100%',minHeight:'100vh',maxHeight:'100vh',height:'100%',overflow:'hidden',position:'relative'}}>

    <Modal open={modal} footer={false} closable={false} width={400} className='absolute top-0 left-[38%]'> 
      <div className='relative'>
        <IoClose onClick={()=>setmodal(!modal)} size={16} className='absolute cursor-pointer right-0 top-0' />
        <h6 className='font-[700] text-[14px]'>Customize the Fidelitus Lead Report</h6>
        <h6 className='text-[11px] bg-slate-100 p-2'>Use the below option to make a customization of fidelitus leads assigned from vertical to verticals</h6>

        
        <div className='flex justify-evenly my-2 -ml-1'>
           
           
          <div onClick={()=>{setstep(1);setmodal(false)}} className={`flex items-center border p-1 cursor-pointer ${step === 1 && 'bg-slate-800 border-slate-800 text-white'} `}>
          <AiOutlineTable size={18} className='mr-1' />
          <h6 className='text-[11px]'>Table Report</h6>
          </div>

          <div onClick={()=>{setstep(2);setmodal(false)}} className={`flex items-center border p-1 cursor-pointer ${step === 2 && 'bg-slate-800 border-slate-800 text-white'} `}>
          <VscGraph size={18} className='mr-1' />
          <h6 className='text-[11px]'>Graph Report</h6>
          </div>

          <div onClick={()=>{setstep(3);setmodal(false)}} className={`flex items-center border p-1 cursor-pointer ${step === 3 && 'bg-slate-800 border-slate-800 text-white'} `}>
          <CiCalendarDate size={18} className='mr-1' />
          <h6 className='text-[11px]'>Calendar Report</h6>
          </div>
        </div>

      </div>
    </Modal> 

    <Modal open={modal1} footer={false} closable={false} width={'80%'} height={'100%'} className='absolute top-0 left-[10%]'> 
      <div className='relative'>
        <IoClose onClick={()=>setmodal1(!modal1)} size={16} className='absolute cursor-pointer right-0 top-0' />
        <h6 className='font-[700] text-[13px]'>Lead Assigned For Date {moment(selectedDate)?.format('LL')} - ({fidelitusIndivisualDateBasedData?.pagination?.total})</h6>

        
        <div className='max-h-[85vh] mt-2 border-t border-l border-r overflow-y-scroll'>
          <div className='sticky top-0 z-50 flex  py-[-5px] border-b bg-white border-slate-200 relative'>
              <h6 className='sticky top-0 z-50 text-[12px] w-[50px]  px-2 py-1 font-[600] text-slate-600 border-r '>Sl No</h6>
              <h6 className='sticky top-0 z-50  text-[12px] w-[20%] px-2 py-1 font-[600] text-slate-600 border-r'>Company Name</h6>
              <h6 className='sticky top-0 z-50  text-[12px] w-[25%]  px-2 py-1 bg-gray-50 font-[600] text-slate-600 border-r'>Contact Person Info</h6>
              <h6 className='sticky top-0 z-50  text-[12px] w-[20%] px-2 py-1 font-[600] text-slate-600 border-r'>Created Department</h6>
              <h6 className='sticky top-0 z-50  text-[12px] w-[20%] px-2 py-1 font-[600] text-slate-600 border-r'>Assigned Department</h6>
              <h6 className='sticky top-0 z-50  text-[12px] w-[12%]  px-2 py-1 font-[600] text-slate-600 sticky right-0'>Status </h6>
          </div>
          {fidelitusIndivisualDateBasedData?.datas?.map((d,i)=>(
          <div key={d?._id} className='flex z-50 border-b border-slate-200 '  >
              <h6 className='text-[12px] font-[500] w-[50px]  px-2 py-1  border-r border-slate-200 '>{(page > 1 ? i+1+ (25 * (page - 1)) : i+1 )}</h6>
              <h6 className='text-[12px] font-[500] w-[20%] px-2 py-1 font-[800] border-r border-slate-200'>{d?.fidelitus_lead?.company_name}
              {d?.zone !== undefined && <h6 className='text-[10px]'>Zone : {d?.fidelitus_lead?.zone}</h6>}</h6>
              <h6 className='text-[12px] font-[500]  w-[25%] bg-gray-50  px-2 py-1 break-all border-r border-slate-200'>
                <div>
                <h6>{d?.fidelitus_lead?.contact_name}</h6>  
                <span>{d?.fidelitus_lead?.phone} / {d?.fidelitus_lead?.email}  </span>
                </div>
              </h6>
              <h6 className='text-[11px] font-[500]  w-[20%] px-2 py-1 font-[800] border-r border-slate-200'>
                {d?.fidelitus_lead?.lead_owner?._id !== undefined}
                <h6 className='text-[10px]'>Department Info  : {d?.fidelitus_lead?.department_id?.department_name}</h6>
                <h6 className='text-[10px]'>Created By  : {d?.fidelitus_lead?.lead_owner?.name}</h6>
                <h6 className='text-[10px] font-[500]'>{d?.fidelitus_lead?.previously_handled_by}</h6>
              </h6>
              
              <div className=' w-[20%] border-r px-2 py-1'>
              <h6 className='text-[10px] font-[700] '>Department Info  : {d?.department_id?.department_name}</h6>
              {d?.converted == '1' && <h6 onClick={()=>navigate('/leads/contacts/detail',{state:d?.lead_id?._id === undefined ? d?.lead_id : d?.lead_id?._id})} className='text-[10px] cursor-pointer font-[500]  mt-1 underline text-blue-500'>View Details</h6>}
                
             
              </div>                     
             
              <h6 className='text-[12px] flex items-center uppercase font-[500] w-[12%]  px-2 py-1 truncate border-slate-200 '>
                 {d?.converted == '3' && <span className='bg-blue-100 px-[6px] py-[2px] rounded text-[10px] text-blue-600 font-[700]'>Pending</span>}
                 {d?.converted == '2' && <span className='bg-red-100 px-[6px] py-[2px] rounded text-[10px] text-red-600 font-[700]'>Rejected</span>}
                 {d?.converted == '1' && <span className='bg-green-100 px-[6px] py-[2px] rounded text-[10px] text-green-600 font-[700]'>Converted</span>}
               
                  </h6>
          </div>))}
        </div> 

       
      </div>
    </Modal> 
     
    <Drawer maskStyle={{background:'black',opacity:0.8}} onClose={()=>setpreview(false)}  maskClosable={()=>setpreview(false)} open={preview} width={600} closable={false}>
       
       <div >
         <div className='mb-3 -mt-3 flex items-center justify-between border-b pb-3 border-slate-100'>
          <h6  onClick={()=>setpreview(false)} className='flex cursor-pointer items-center'>
          <HiLogout size={20} className='text-slate-300' /> 
          <span className='ml-2 font-[600]'>Lead Preview</span>
          </h6>

          <h6 onClick={()=>navigate(`/leads/contacts/detail`,{state:selected_lead?._id})} className='border cursor-pointer flex items-center p-1.5 px-2 font-[500] text-[11px] border-slate-100 rounded'>View Full Detail <IoMdArrowForward size={15} className='ml-1' /></h6>
         </div>
         <div className='border relative border-slate-100 '>
          <div className='flex absolute right-2 top-1'>
              <CopyToClipboard text={selected_lead?.email}>
             <AiOutlineMail size={22} className='border cursor-pointer p-1 mr-2 rounded-full text-slate-400' />
             </CopyToClipboard>
             <CopyToClipboard text={selected_lead?.phone}>
             <AiOutlinePhone size={22} className='border cursor-pointer p-1 mr-2 rounded-full text-slate-400'  />
             </CopyToClipboard>
             
             <AiOutlineEdit onClick={()=>navigate('edit',{state:selected_lead?._id})} size={22} className='border cursor-pointer p-1 rounded-full text-slate-400'  />
          </div>
          <div className='flex p-2 items-center border-b   border-slate-100 '>
           <h6 className='bg-purple-200 w-9 h-9 text-[12px] flex items-center justify-center uppercase rounded-full '>{selected_lead?.contact_name?.slice(0,2)}</h6>
           <div className='ml-2'>
            <h6 className='mb-0 font-[700]'>{selected_lead?.contact_name}</h6>
            <h6 className='flex text-[11px] -mt-0.5 items-center text-slate-400'><AiOutlineMail  className='mr-2'/> {selected_lead?.email} <AiOutlinePhone className='mx-2' /> {selected_lead?.phone} </h6>
           </div>
          </div>
          <div className='flex items-center justify-evenly'>
            <div className='border-r  border-slate-100 w-full pl-3 py-2'>
              <h6 className='text-slate-400 text-[11px]'>Lead owner</h6>
              <h6 className='font-[600] text-[12px]'>{selected_lead?.lead_owner?.name}</h6>
            </div>

            <div className='border-r  border-slate-100 w-full pl-3  '>
              <h6 className='text-slate-400 text-[11px]'>Company</h6>
              <h6 className='font-[600] text-[12px]'>{selected_lead?.company_name}</h6>
            </div>

            <div className='border-r  border-slate-100 w-full pl-3  py-2'>
              <h6 className='text-slate-400 text-[11px]'>Job Title</h6>
              <h6 className='font-[600] text-[12px]'>{selected_lead?.designation}</h6>
            </div>

            <div className=' w-full pl-3 py-2'>
              <h6 className='text-slate-400 text-[11px]'>Requirment</h6>
              <h6 className='font-[600] text-[11px]'>{selected_lead?.sqft && 'SQFT,'} {selected_lead?.seater && 'SEATER,'} {selected_lead?.rent && 'RENT,'} {selected_lead?.sale && 'SALE'}</h6>
            </div>

          </div>
         </div>

        {/* <h6>{JSON.stringify(JSON.stringify(selected_lead?.stage?.name?.split(' '))?.includes('Invoice'))}</h6> */}
         <div className='mt-2 flex justify-between'>
         {stage?.map((s,i)=>(
          <h6 key={i} className={` w-full mx-0.5 font-[700] cursor-pointer text-center p-1 text-[11px] ${stage?.find((f)=>JSON.stringify(JSON.stringify(selected_lead?.stage?.name?.split(' '))?.includes(f?.value)) === 'true')?.index > i ? 'bg-green-100 text-green-600' : stage?.find((f)=>JSON.stringify(JSON.stringify(selected_lead?.stage?.name?.split(' '))?.includes(f?.value)) === 'true')?.index < i  ?  'bg-slate-100 text-slate-600' : 'bg-green-600 text-white'}   `}>{s?.value}</h6>
         ))}
         </div>

         <div className='flex items-center my-4 pb-4 border-slate-100 border-b justify-between'>
          <h6 className='text-[11px]'>Lead Source <span className='text-[11px] font-[700]'>{selected_lead?.lead_source?.name}</span> </h6>
          <h6 className='text-[11px]'>Last Activity : <span className='text-[11px] font-[700]'> {moment(selected_lead?.updatedAt).format('lll')}</span></h6>
         </div>


         {(attachment?.datas?.length === 0 && note?.datas?.length === 0 )&& 
              <div className='grid place-items-center mt-20  items-center justify-center'>
                <img src={'https://fidecrmfiles.s3.amazonaws.com/NoDataFound1.png'} alt="no" className='w-40 h-40 object-contain' /> 
                <h6 className='font-bold text-[14px] -mt-10'>No data found</h6>
                <h6 className='font-[500] w-[80%] text-center text-slate-700 text-[12.5px] -mt-2'>Oops we couldn't find any particual notes or reminders added for this particular leads.</h6>
              </div>
         }

         {attachment?.datas?.length !== 0 && 
         <>
         <h6 className='text-[12px] font-[700]'> Reminder <span className='bg-slate-100 p-1 px-2 rounded font-[400] text-slate-800 text-[12px]'>{attachment?.datas?.length}</span></h6>
         {attachment?.datas?.map((n)=>(
         <div className='border mt-2 p-2 rounded border-slate-100'>
              <div className='border-b flex items-center justify-between pb-2 mb-2 border-slate-100'> 
                 <h6 className='text-[11px] flex items-center '><CiStickyNote size={21} className='bg-slate-100 text-blue-600 p-1 mr-2' /> <span className='font-[500] mr-1'>Reminder </span> by <span  className='font-[500] ml-1'> {n?.user_id?.name}</span></h6>
                 <h6 className='text-[10px] text-slate-400 flex items-center '><AiOutlineCalendar size={21} className='p-1 mr-1' /> <span className='font-[500] mr-1'>{moment(n?.createdAt)?.format('lll')}</span></h6>
              </div>   
              <h6 className='text-[12px] font-[500] mb-1'>{n?.title}</h6>
              <h6 className='leading-tight text-slate-400 text-[11px] font-[400]'>{n?.description} <span className='text-black'> {moment(n?.on_date_time)?.format('lll')} </span></h6>
              
         </div>
         ))}
         </>}

         {note?.datas?.length !== 0 && 
         <>
         <h6 className='text-[12px] font-[700] my-4'> Notes <span className='bg-slate-100 p-1 px-2 rounded font-[400] text-slate-800 text-[12px]'>{note?.datas?.length}</span></h6>
         
         {note?.datas?.map((n)=>(
         <div className='border mt-2 p-2 rounded border-slate-100'>
              <div className='border-b flex items-center justify-between pb-2 mb-2 border-slate-100'> 
                 <h6 className='text-[11px] flex items-center '><CiStickyNote size={21} className='bg-slate-100 text-blue-600 p-1 mr-2' /> <span className='font-[500] mr-1'>Note </span> by <span  className='font-[500] ml-1'> {n?.user_id?.name}</span></h6>
                 <h6 className='text-[10px] text-slate-400 flex items-center '><AiOutlineCalendar size={21} className='p-1 mr-1' /> <span className='font-[500] mr-1'>{moment(n?.createdAt)?.format('lll')}</span></h6>
              </div>   
              <h6 className='text-[12px] font-[500] mb-1'>{n?.note_title}</h6>
              <h6 className='leading-tight text-slate-400 text-[11px] font-[400]'>{n?.summary}</h6>
              {(n?.note_file !== null && n?.note_file !== '' && n?.note_file !== undefined) &&
                         <>
                         <div className='mt-1 border-slate-100  flex relative py-1'>
                          <img src='https://cdn-icons-png.flaticon.com/512/179/179483.png' alt="no" className='w-5 h-5 object-contain' />
                          <div className='ml-1 -mt-0.5'>
                          <h6 className='font-[600] text-[11px]'>{returnfilename(n?.note_file)}</h6>
                          <h6 className='font-[600] text-[9px] -mt-0.5'>{getFileSizeString(n?.filesize)} </h6>
                          </div>
                         {(n?.note_file !== null && n?.note_file !== undefined && n?.note_file !== '') && <AiOutlineCloudDownload size={23} className='absolute right-1 top-1.5 bg-slate-100 p-1 rounded-full' onClick={()=>handleDownload(`${process.env.REACT_APP_BACKEND_IMAGE_URL}${n?.note_file}`,returnfilename(n?.note_file))}/>}

                          </div>
                          </>}
         </div>
         ))}
         </>}


       </div>
    </Drawer>
    
    
    <div > 
        <div className='flex'>
        <div className='w-44 border-r'>
        <DashboardMenu />
        </div>  
        <div className='px-3 w-[87%] pt-3'>
        <div  >
          <div>
          <div className='flex flex-col  border-b pb-2 mb-5'>

          <h6 className='font-[700] text-[14px] '>Fidelitus Master Leads {step === 1 && `(${leads?.pagination?.total})`}</h6>

          <div className='flex items-center mt-2' >
            <div className='flex items-center  text-[12px] mr-2'>
                <h6 className='mr-2 font-[600]'>{page === 1 ? leads?.datas?.length > 0 ? 1 : 0 : (page - 1) * leads?.pagination?.limit } - {leads?.pagination?.limit} of {leads?.pagination?.total} </h6>
                <IconButton  onClick={resetfilter} ><BsArrowRepeat size={16} /></IconButton>

                <div>
                <IconButton onClick={()=>{page > 1 ? setpage(page-1) : console.log('')}}><FiChevronLeft className={`${page === 1 ? 'opacity-50' : 'opacity-100'}`}  size={16} /></IconButton>
                <IconButton onClick={()=>{ page < leads?.pagination?.totalPages  ? setpage(page+1) : console.log('')}} ><FiChevronRight className={`${(leads?.pagination?.totalPages === page || leads?.datas?.length === 0) ? 'opacity-50' : 'opacity-100'}`} size={16} /></IconButton>
                </div>
            </div>

            
               {(step == 1 || step == 2) &&
                <>
                <DatePicker allowClear={true} onClear={()=>setsearch({...search,from_date:'',from_date1:''})} size='small'  ampm={false} placeholder='From Date' className='text-[11px] py-[4px]   w-28 border-slate-300 mr-1'  value={search?.from_date} onChange={(v,v1) => {setsearch({...search,from_date:v,from_date1:v1})}} /> 

                <DatePicker allowClear={true} onClear={()=>setsearch({...search,to_date:'',to_date1:''})} size='small'  ampm={false} placeholder='To Date' className='text-[11px] py-[4px]  w-28 border-slate-300 mr-1'  value={search?.to_date} onChange={(v,v1) => {setsearch({...search,to_date:v,to_date1:v1})}} /> 
                {step == 1 &&
                <input id="search_text" type='text' value={search.text} placeholder='Search text' onChange={(e)=>setsearch({...search,text:e.target.value})} className='border py-[4px] focus:ring-0 mr-1 focus:outline-0 text-[14px]  w-28 px-1 rounded-md border-slate-300' />}
                </>}


            <div className='border border-slate-300 rounded-md py-[2px] mr-1'>
            <Select
                placeholder='Create Department'
                bordered={false}
                size='small'
                allowClear={true}
                onClear={()=>setcreated_department(null)}
                defaultValue={created_department}
                value={created_department}
                style={{ width: 110 }}
                onChange={(v)=>handlechange(v,'department1')}
                options={departments}
            />
            </div>
          

            <div className='border border-slate-300  mr-1 rounded-md py-[2px]'>
            <Select
                placeholder='Select User'
                bordered={false}
                size='small'
                filterOption={false}
                showSearch={true}
                allowClear={true}
                onClear={()=>setselected_user(null)}
                defaultValue={selected_user}
                value={selected_user}
                style={{ width: 110 }}
                onSearch={(e)=>searchUser(e,'user')}
                options={users}
                onChange={(v)=>handlechange(v,'user')}
            />
            </div>

            <div className='border border-slate-300 rounded-md py-[2px]'>
              <Select
                  placeholder='Assigned Department'
                  bordered={false}
                  allowClear={true}
                  onClear={()=>setselected_department(null)}
                  size='small'
                  defaultValue={selected_department}
                  value={selected_department}
                  style={{ width: 110 }}
                  onChange={(v)=>handlechange(v,'department')}
                  options={departments}
              />
            </div>

            {/* {step == 1 &&
            <div className='border border-slate-300 ml-2 rounded-md py-[2px]'>
              <Select
                  placeholder='Select Zone'
                  bordered={false}
                  size='small'
                  defaultValue={search?.zone !== '' ? search?.zone : null}
                  value={search?.zone !== '' ? search?.zone : null}
                  style={{ width: 110 }}
                  onChange={(v)=>handlechange(v,'zone')}
                  options={options}
              />
            </div>} */}

        

            {/* <div className='ml-1 rounded-md border border-slate-300 py-[2px]'>
            <Select
                placeholder='Select Stage'
                bordered={false}
                size='small'
                defaultValue={selected_stage}
                value={selected_stage}
                style={{ width: 110 }}
                onChange={(v)=>handlechange(v,'stages')}
                options={stages}
            />
            </div> */}

          
            <div className='ml-1 mr-1'>
            <BiFilterAlt  onClick={()=>applyfilterfunction(1)} size={25} className='bg-slate-600 p-[5px] text-white cursor-pointer rounded ml-1' />
            {/* <ButtonFilledAutoWidth btnName="Add Filter"  />  */}
            </div> 

            <RxReset  onClick={resetfilter} size={25} className='bg-slate-200 p-[5px] text-salte-800 cursor-pointer rounded ' />
            {/* <ButtonFilledAutoWidth btnName="Reset Filter" onClick={resetfilter} /> */}

            <IoFilterCircleOutline onClick={()=>setmodal(!modal)} size={25} className='bg-slate-600 p-[5px] text-white cursor-pointer rounded ml-1' />
          </div>
          </div>

          {step === 1 &&
          <>
          {leads?.datas?.length > 0 && 
          <div className='max-h-[85vh] border-t border-l border-r overflow-y-scroll'>
          <div className='sticky top-0 z-50 flex  py-[-5px] border-b bg-white border-slate-200 relative'>
              <h6 className='sticky top-0 z-50 text-[12px] w-[50px]  px-2 py-1 font-[600] text-slate-600 border-r '>Sl No</h6>
              <h6 className='sticky top-0 z-50  text-[12px] bg-gray-50 w-[140px] px-2 py-1 font-[600] text-slate-600 border-r'>Company / Contact Person Info</h6>
              {/* <h6 className='sticky top-0 z-50  text-[12px] w-[160px]  px-2 py-1 bg-gray-50 font-[600] text-slate-600 border-r'>Contact Person Info</h6> */}
              {/* <h6 className='sticky top-0 z-50  text-[12px] w-[140px] px-2 py-1 font-[600] text-slate-600 border-r'>Created Department</h6> */}
              <h6 className='sticky top-0 z-50  text-[12px] w-[120px]  px-2 py-1 font-[600] text-slate-600 border-r'>Transaction</h6>
              <h6 className='sticky top-0 z-50  text-[12px] w-[120px]  px-2 py-1 font-[600] text-slate-600 border-r'>Project</h6>
              <h6 className='sticky top-0 z-50  text-[12px] w-[120px]  px-2 py-1 font-[600] text-slate-600 border-r'>HR</h6>
              <h6 className='sticky top-0 z-50  text-[12px] w-[120px]  px-2 py-1 font-[600] text-slate-600  border-r'>FMS</h6>
              <h6 className='sticky top-0 z-50  text-[12px] w-[120px]  px-2 py-1 font-[600] text-slate-600  border-r'>Gallery</h6>
              <h6 className='sticky top-0 z-50  text-[12px] w-[120px]  px-2 py-1 font-[600] text-slate-600  border-r'>Media</h6>
              <h6 className='sticky top-0 z-50  text-[12px] w-[120px]  px-2 py-1 font-[600] text-slate-600  border-r'>Foundation</h6>
              <h6 className='sticky top-0 z-50  text-[12px] w-[120px]  px-2 py-1 font-[600] text-slate-600 sticky right-0'>Assign / Convert </h6>
          </div>
        
          {leads?.datas?.map((d,i)=>(
          <div key={d?._id} className='flex z-50 border-b border-slate-200 '  >
              <h6 className='text-[12px] font-[500] w-[50px]  px-2 py-1  border-r border-slate-200 '>{(page > 1 ? i+1+ (25 * (page - 1)) : i+1 )}</h6>
              <h6 className='text-[12px] bg-gray-50 font-[500] w-[140px] break-all px-2 py-1 font-[800] border-r border-slate-200'>{d?.company_name}
              {d?.zone !== undefined && <h6 className='text-[10px]'>Zone : {d?.zone}</h6>}
              <h6>{d?.contact_name}</h6>  
              <span>{d?.phone} / {d?.email}  </span>
              {d?.department_id?.department_name}
                <h6 className='text-[10px]'>Created By : {d?.lead_owner?.name}</h6>
                <h6 className='text-[10px] font-[500]'>{d?.previously_handled_by}</h6>

              </h6>
              {/* <h6 className='text-[12px] font-[500] w-[160px] bg-gray-50  px-2 py-1 break-all border-r border-slate-200'>
                <div>
                <h6>{d?.contact_name}</h6>  
                <span>{d?.phone} / {d?.email}  </span>
                </div>
              </h6> */}
              {/* <h6 className='text-[11px] font-[500] w-[140px]  px-2 py-1 font-[800] border-r border-slate-200'>
                {d?.department_id?.department_name}
                <h6 className='text-[10px]'>Created By : {d?.lead_owner?.name}</h6>
                <h6 className='text-[10px] font-[500]'>{d?.previously_handled_by}</h6>
              </h6> */}
              <Tooltip title={(d?.fidelitusleadassigneds?.find((f)=>f?.department_id?._id === departments?.find((v)=>v.label == "Transaction Team")?.value) !== null && d?.fidelitusleadassigneds?.find((f)=>f?.department_id?._id === departments?.find((v)=>v.label == "Transaction Team")?.value) !== undefined  && (d?.fidelitusleadassigneds?.find((f)=>f?.department_id?._id === departments?.find((v)=>v.label == "Transaction Team")?.value)?.converted === '1' || d?.fidelitusleadassigneds?.find((f)=>f?.department_id?._id === departments?.find((v)=>v.label == "Transaction Team")?.value)?.converted === '2')) &&   `Remarks : ${d?.fidelitusleadassigneds?.find((f)=>f?.department_id?._id === departments?.find((v)=>v.label == "Transaction Team")?.value)?.remarks}`}>
              <h6 className={`text-[12px] w-[120px]  px-2 py-1 font-[600] text-slate-600 border-r `}>
                       {d?.fidelitusleadassigneds?.length > 0 ? 
                   (d?.fidelitusleadassigneds?.find((f)=>f?.department_id?._id === departments?.find((v)=>v.label == "Transaction Team")?.value) !== null && d?.fidelitusleadassigneds?.find((f)=>f?.department_id?._id === departments?.find((v)=>v.label == "Transaction Team")?.value) !== undefined)
                   ?      
                   d?.fidelitusleadassigneds?.find((f)=>f?.department_id?._id === departments?.find((v)=>v.label == "Transaction Team")?.value)?.converted === '1' ?
                  <span className='flex items-center text-[11px] font-[700] mr-2'><h6 className='w-[10px] h-[10px] bg-green-400 mr-1'></h6> Converted</span> :
                d?.fidelitusleadassigneds?.find((f)=>f?.department_id?._id === departments?.find((v)=>v.label == "Transaction Team")?.value)?.converted === '2' ?
                <span className='flex items-center text-[11px] font-[700] mr-2'><h6 className='w-[10px] h-[10px] bg-red-400 mr-1'></h6> Hold</span> :
                d?.fidelitusleadassigneds?.find((f)=>f?.department_id?._id === departments?.find((v)=>v.label == "Transaction Team")?.value)?.converted === '3' ?
                <span className='flex items-center text-[11px] font-[700] mr-2'><h6 className='w-[10px] h-[10px] bg-yellow-400 mr-1'></h6> Pending</span> : 
                d?.fidelitusleadassigneds?.find((f)=>f?.department_id?._id === departments?.find((v)=>v.label == "Transaction Team")?.value)?.converted === '4' ?
                <span className='flex items-center text-[11px] font-[700] mr-2'><h6 className='w-[10px] h-[10px] bg-blue-400 mr-1'></h6> Progress</span>
                : '' : '' : ''}
                
                       
                      {d?.fidelitusleadassigneds?.find((f)=>f?.department_id?._id === departments?.find((v)=>v.label == "Transaction Team")?.value) !== undefined && <h6 className='text-[9px] text-black'>Assigned Date : {moment(d?.fidelitusleadassigneds?.find((f)=>f?.department_id?._id === departments?.find((v)=>v.label == "Transaction Team")?.value)?.createdAt)?.format('D-M-YYYY')}</h6>}
                      {(d?.fidelitusleadassigneds?.find((f)=>f?.department_id?._id === departments?.find((v)=>v.label == "Transaction Team")?.value)?.converted === '1' && d?.fidelitusleadassigneds?.find((f)=>f?.department_id?._id === departments?.find((v)=>v.label == "Transaction Team")?.value)?.lead_id?._id !== undefined && d?.fidelitusleadassigneds?.find((f)=>f?.department_id?._id === departments?.find((v)=>v.label == "Transaction Team")?.value)?.lead_id?._id !== null) && 
                      <div className='mt-1'>
                       <h6 className='text-[10px] font-[600] mt-1'>Lead Stage : {d?.fidelitusleadassigneds?.find((f)=>f?.department_id?._id === departments?.find((v)=>v.label == "Transaction Team")?.value)?.lead_id?.stage?.name}</h6>
                       <h6 onClick={()=>navigate('/leads/contacts/detail',{state:d?.fidelitusleadassigneds?.find((f)=>f?.department_id?._id === departments?.find((v)=>v.label == "Transaction Team")?.value)?.lead_id?._id})} className='text-[10px] cursor-pointer font-[500]  mt-1 underline text-blue-500'>View Details</h6>
                      </div> }

                     </h6>
              </Tooltip>

              <Tooltip disableInteractive title={(d?.fidelitusleadassigneds?.find((f)=>f?.department_id?._id === departments?.find((v)=>v.label == "Project Team")?.value) !== null  && (d?.fidelitusleadassigneds?.find((f)=>f?.department_id?._id === departments?.find((v)=>v.label == "Project Team")?.value)?.converted === '1' || d?.fidelitusleadassigneds?.find((f)=>f?.department_id?._id === departments?.find((v)=>v.label == "Project Team")?.value)?.converted === '2')) &&   `Remarks : ${d?.fidelitusleadassigneds?.find((f)=>f?.department_id?._id === departments?.find((v)=>v.label == "Project Team")?.value)?.remarks}`}>
              <h6 className={`text-[12px] w-[120px]  px-2 py-1 font-[600] text-slate-600 border-r `
                 
                     }>
                       {d?.fidelitusleadassigneds?.length > 0 ? 
                   (d?.fidelitusleadassigneds?.find((f)=>f?.department_id?._id === departments?.find((v)=>v.label == "Project Team")?.value) !== null && d?.fidelitusleadassigneds?.find((f)=>f?.department_id?._id === departments?.find((v)=>v.label == "Project Team")?.value) !== undefined)
                   ?      
                   d?.fidelitusleadassigneds?.find((f)=>f?.department_id?._id === departments?.find((v)=>v.label == "Project Team")?.value)?.converted === '1' ?
                  <span className='flex items-center text-[11px] font-[700] mr-2'><h6 className='w-[10px] h-[10px] bg-green-400 mr-1'></h6> Converted</span> :
                d?.fidelitusleadassigneds?.find((f)=>f?.department_id?._id === departments?.find((v)=>v.label == "Project Team")?.value)?.converted === '2' ?
                <span className='flex items-center text-[11px] font-[700] mr-2'><h6 className='w-[10px] h-[10px] bg-red-400 mr-1'></h6> Hold</span> :
                d?.fidelitusleadassigneds?.find((f)=>f?.department_id?._id === departments?.find((v)=>v.label == "Project Team")?.value)?.converted === '3' ?
                <span className='flex items-center text-[11px] font-[700] mr-2'><h6 className='w-[10px] h-[10px] bg-yellow-400 mr-1'></h6> Pending</span> :
                d?.fidelitusleadassigneds?.find((f)=>f?.department_id?._id === departments?.find((v)=>v.label == "Project Team")?.value)?.converted === '4' ?
                <span className='flex items-center text-[11px] font-[700] mr-2'><h6 className='w-[10px] h-[10px] bg-blue-400 mr-1'></h6> Progress</span> : '' : '' : ''}
                      {d?.fidelitusleadassigneds?.find((f)=>f?.department_id?._id === departments?.find((v)=>v.label == "Project Team")?.value) !== undefined && <h6 className='text-[9px] text-black'>Assigned Date : {moment(d?.fidelitusleadassigneds?.find((f)=>f?.department_id?._id === departments?.find((v)=>v.label == "Project Team")?.value)?.createdAt)?.format('D-M-YYYY')}</h6>}
                      

                      {(d?.fidelitusleadassigneds?.find((f)=>f?.department_id?._id === departments?.find((v)=>v.label == "Project Team")?.value)?.converted === '1' && d?.fidelitusleadassigneds?.find((f)=>f?.department_id?._id === departments?.find((v)=>v.label == "Project Team")?.value)?.lead_id?._id !== undefined && d?.fidelitusleadassigneds?.find((f)=>f?.department_id?._id === departments?.find((v)=>v.label == "Project Team")?.value)?.lead_id?._id !== null) && 
                      <div className='mt-1'>
                       <h6 className='text-[10px] font-[600] mt-1'>Lead Stage : {d?.fidelitusleadassigneds?.find((f)=>f?.department_id?._id === departments?.find((v)=>v.label == "Project Team")?.value)?.lead_id?.stage?.name}</h6>
                       <h6 onClick={()=>navigate('/leads/contacts/detail',{state:d?.fidelitusleadassigneds?.find((f)=>f?.department_id?._id === departments?.find((v)=>v.label == "Project Team")?.value)?.lead_id?._id})} className='text-[10px] cursor-pointer font-[500]  mt-1 underline text-blue-500'>View Details</h6>
                      </div> }
                      </h6> 
              </Tooltip>

              <Tooltip disableInteractive title={(d?.fidelitusleadassigneds?.find((f)=>f?.department_id?._id === departments?.find((v)=>v.label == "HR Team")?.value) !== null  && (d?.fidelitusleadassigneds?.find((f)=>f?.department_id?._id === departments?.find((v)=>v.label == "HR Team")?.value)?.converted === '1' || d?.fidelitusleadassigneds?.find((f)=>f?.department_id?._id === departments?.find((v)=>v.label == "HR Team")?.value)?.converted === '2')) &&   `Remarks : ${d?.fidelitusleadassigneds?.find((f)=>f?.department_id?._id === departments?.find((v)=>v.label == "HR Team")?.value)?.remarks}`}>
              <h6 className={`text-[12px] w-[120px]  px-2 py-1 font-[600] text-slate-600 border-r `
               
                     }>
                      
               {d?.fidelitusleadassigneds?.length > 0 ? 
                   (d?.fidelitusleadassigneds?.find((f)=>f?.department_id?._id === departments?.find((v)=>v.label == "HR Team")?.value) !== null && d?.fidelitusleadassigneds?.find((f)=>f?.department_id?._id === departments?.find((v)=>v.label == "HR Team")?.value) !== undefined)
                   ?      
                   d?.fidelitusleadassigneds?.find((f)=>f?.department_id?._id === departments?.find((v)=>v.label == "HR Team")?.value)?.converted === '1' ?
                  <span className='flex items-center text-[11px] font-[700] mr-2'><h6 className='w-[10px] h-[10px] bg-green-400 mr-1'></h6> Converted</span> :
                d?.fidelitusleadassigneds?.find((f)=>f?.department_id?._id === departments?.find((v)=>v.label == "HR Team")?.value)?.converted === '2' ?
                <span className='flex items-center text-[11px] font-[700] mr-2'><h6 className='w-[10px] h-[10px] bg-red-400 mr-1'></h6> Hold</span> :
                d?.fidelitusleadassigneds?.find((f)=>f?.department_id?._id === departments?.find((v)=>v.label == "HR Team")?.value)?.converted === '3' ?
                <span className='flex items-center text-[11px] font-[700] mr-2'><h6 className='w-[10px] h-[10px] bg-yellow-400 mr-1'></h6> Pending</span> :
                d?.fidelitusleadassigneds?.find((f)=>f?.department_id?._id === departments?.find((v)=>v.label == "HR Team")?.value)?.converted === '4' ?
                <span className='flex items-center text-[11px] font-[700] mr-2'><h6 className='w-[10px] h-[10px] bg-blue-400 mr-1'></h6> Progress</span> : '' : '' : ''}
                      
                      {d?.fidelitusleadassigneds?.find((f)=>f?.department_id?._id === departments?.find((v)=>v.label == "HR Team")?.value) !== undefined && <h6 className='text-[9px] text-black'>Assigned Date : {moment(d?.fidelitusleadassigneds?.find((f)=>f?.department_id?._id === departments?.find((v)=>v.label == "HR Team")?.value)?.createdAt)?.format('D-M-YYYY')}</h6>}
                      {(d?.fidelitusleadassigneds?.find((f)=>f?.department_id?._id === departments?.find((v)=>v.label == "HR Team")?.value)?.converted === '1' && d?.fidelitusleadassigneds?.find((f)=>f?.department_id?._id === departments?.find((v)=>v.label == "HR Team")?.value)?.lead_id?._id !== undefined && d?.fidelitusleadassigneds?.find((f)=>f?.department_id?._id === departments?.find((v)=>v.label == "HR Team")?.value)?.lead_id?._id !== null) && 
                      <div className='mt-1'>
                       <h6 className='text-[10px] font-[600] mt-1'>Lead Stage : {d?.fidelitusleadassigneds?.find((f)=>f?.department_id?._id === departments?.find((v)=>v.label == "HR Team")?.value)?.lead_id?.stage?.name}</h6>
                       <h6 onClick={()=>navigate('/leads/contacts/detail',{state:d?.fidelitusleadassigneds?.find((f)=>f?.department_id?._id === departments?.find((v)=>v.label == "HR Team")?.value)?.lead_id?._id})} className='text-[10px] cursor-pointer font-[500]  mt-1 underline text-blue-500'>View Details</h6>
                      </div> }

                      </h6> 
              </Tooltip>

              <Tooltip disableInteractive title={(d?.fidelitusleadassigneds?.find((f)=>f?.department_id?._id === departments?.find((v)=>v.label == "FMS Team")?.value) !== null  && (d?.fidelitusleadassigneds?.find((f)=>f?.department_id?._id === departments?.find((v)=>v.label == "FMS Team")?.value)?.converted === '1' || d?.fidelitusleadassigneds?.find((f)=>f?.department_id?._id === departments?.find((v)=>v.label == "FMS Team")?.value)?.converted === '2')) &&   `Remarks : ${d?.fidelitusleadassigneds?.find((f)=>f?.department_id?._id === departments?.find((v)=>v.label == "FMS Team")?.value)?.remarks}`}>
                      <h6 className={`text-[12px] w-[120px]  px-2 py-1 font-[600] text-slate-600 border-r`}>

               {d?.fidelitusleadassigneds?.length > 0 ? 
                   (d?.fidelitusleadassigneds?.find((f)=>f?.department_id?._id === departments?.find((v)=>v.label == "FMS Team")?.value) !== null && d?.fidelitusleadassigneds?.find((f)=>f?.department_id?._id === departments?.find((v)=>v.label == "FMS Team")?.value) !== undefined)
                   ?      
                   d?.fidelitusleadassigneds?.find((f)=>f?.department_id?._id === departments?.find((v)=>v.label == "FMS Team")?.value)?.converted === '1' ?
                   <span className='flex items-center text-[11px] font-[700] mr-2'><h6 className='w-[10px] h-[10px] bg-green-400 mr-1'></h6> Converted</span> :
                   d?.fidelitusleadassigneds?.find((f)=>f?.department_id?._id === departments?.find((v)=>v.label == "FMS Team")?.value)?.converted === '2' ?
                   <span className='flex items-center text-[11px] font-[700] mr-2'><h6 className='w-[10px] h-[10px] bg-red-400 mr-1'></h6> Hold</span> :
                   d?.fidelitusleadassigneds?.find((f)=>f?.department_id?._id === departments?.find((v)=>v.label == "FMS Team")?.value)?.converted === '3' ?
                   <span className='flex items-center text-[11px] font-[700] mr-2'><h6 className='w-[10px] h-[10px] bg-yellow-400 mr-1'></h6> Pending</span> :
                   d?.fidelitusleadassigneds?.find((f)=>f?.department_id?._id === departments?.find((v)=>v.label == "FMS Team")?.value)?.converted === '4' ?
                   <span className='flex items-center text-[11px] font-[700] mr-2'><h6 className='w-[10px] h-[10px] bg-blue-400 mr-1'></h6> Progress</span> : '' : '' : ''}
                     
                     {d?.fidelitusleadassigneds?.find((f)=>f?.department_id?._id === departments?.find((v)=>v.label == "FMS Team")?.value) !== undefined && <h6 className='text-[9px] text-black'>Assigned Date : {moment(d?.fidelitusleadassigneds?.find((f)=>f?.department_id?._id === departments?.find((v)=>v.label == "FMS Team")?.value)?.createdAt)?.format('D-M-YYYY')}</h6>}
                     {(d?.fidelitusleadassigneds?.find((f)=>f?.department_id?._id === departments?.find((v)=>v.label == "FMS Team")?.value)?.converted === '1' && d?.fidelitusleadassigneds?.find((f)=>f?.department_id?._id === departments?.find((v)=>v.label == "FMS Team")?.value)?.lead_id?._id !== undefined && d?.fidelitusleadassigneds?.find((f)=>f?.department_id?._id === departments?.find((v)=>v.label == "FMS Team")?.value)?.lead_id?._id !== null) && 
                      <div className='mt-1'>
                       <h6 className='text-[10px] font-[600] mt-1'>Lead Stage : {d?.fidelitusleadassigneds?.find((f)=>f?.department_id?._id === departments?.find((v)=>v.label == "FMS Team")?.value)?.lead_id?.stage?.name}</h6>
                       <h6 onClick={()=>navigate('/leads/contacts/detail',{state:d?.fidelitusleadassigneds?.find((f)=>f?.department_id?._id === departments?.find((v)=>v.label == "FMS Team")?.value)?.lead_id?._id})} className='text-[10px] cursor-pointer font-[500]  mt-1 underline text-blue-500'>View Details</h6>
                      </div> }

                      </h6> 
              </Tooltip>   

               <Tooltip disableInteractive title={(d?.fidelitusleadassigneds?.find((f)=>f?.department_id?._id === departments?.find((v)=>v.label == "Fidelitus Gallery")?.value) !== null  && (d?.fidelitusleadassigneds?.find((f)=>f?.department_id?._id === departments?.find((v)=>v.label == "Fidelitus Gallery")?.value)?.converted === '1' || d?.fidelitusleadassigneds?.find((f)=>f?.department_id?._id === departments?.find((v)=>v.label == "Fidelitus Gallery")?.value)?.converted === '2')) &&   `Remarks : ${d?.fidelitusleadassigneds?.find((f)=>f?.department_id?._id === departments?.find((v)=>v.label == "Fidelitus Gallery")?.value)?.remarks}`}>
                      <h6 className={`text-[12px] w-[120px]  px-2 py-1 font-[600] text-slate-600 border-r`}>

               {d?.fidelitusleadassigneds?.length > 0 ? 
                   (d?.fidelitusleadassigneds?.find((f)=>f?.department_id?._id === departments?.find((v)=>v.label == "Fidelitus Gallery")?.value) !== null && d?.fidelitusleadassigneds?.find((f)=>f?.department_id?._id === departments?.find((v)=>v.label == "Fidelitus Gallery")?.value) !== undefined)
                   ?      
                   d?.fidelitusleadassigneds?.find((f)=>f?.department_id?._id === departments?.find((v)=>v.label == "Fidelitus Gallery")?.value)?.converted === '1' ?
                   <span className='flex items-center text-[11px] font-[700] mr-2'><h6 className='w-[10px] h-[10px] bg-green-400 mr-1'></h6> Converted</span> :
                   d?.fidelitusleadassigneds?.find((f)=>f?.department_id?._id === departments?.find((v)=>v.label == "Fidelitus Gallery")?.value)?.converted === '2' ?
                   <span className='flex items-center text-[11px] font-[700] mr-2'><h6 className='w-[10px] h-[10px] bg-red-400 mr-1'></h6> Hold</span> :
                   d?.fidelitusleadassigneds?.find((f)=>f?.department_id?._id === departments?.find((v)=>v.label == "Fidelitus Gallery")?.value)?.converted === '3' ?
                   <span className='flex items-center text-[11px] font-[700] mr-2'><h6 className='w-[10px] h-[10px] bg-yellow-400 mr-1'></h6> Pending</span> :
                   d?.fidelitusleadassigneds?.find((f)=>f?.department_id?._id === departments?.find((v)=>v.label == "Fidelitus Gallery")?.value)?.converted === '4' ?
                   <span className='flex items-center text-[11px] font-[700] mr-2'><h6 className='w-[10px] h-[10px] bg-blue-400 mr-1'></h6> Progress</span> : '' : '' : ''}
                     
                     {d?.fidelitusleadassigneds?.find((f)=>f?.department_id?._id === departments?.find((v)=>v.label == "Fidelitus Gallery")?.value) !== undefined && <h6 className='text-[9px] text-black'>Assigned Date : {moment(d?.fidelitusleadassigneds?.find((f)=>f?.department_id?._id === departments?.find((v)=>v.label == "Fidelitus Gallery")?.value)?.createdAt)?.format('D-M-YYYY')}</h6>}
                     {(d?.fidelitusleadassigneds?.find((f)=>f?.department_id?._id === departments?.find((v)=>v.label == "Fidelitus Gallery")?.value)?.converted === '1' && d?.fidelitusleadassigneds?.find((f)=>f?.department_id?._id === departments?.find((v)=>v.label == "Fidelitus Gallery")?.value)?.lead_id?._id !== undefined && d?.fidelitusleadassigneds?.find((f)=>f?.department_id?._id === departments?.find((v)=>v.label == "Fidelitus Gallery")?.value)?.lead_id?._id !== null) && 
                      <div className='mt-1'>
                       <h6 className='text-[10px] font-[600] mt-1'>Lead Stage : {d?.fidelitusleadassigneds?.find((f)=>f?.department_id?._id === departments?.find((v)=>v.label == "Fidelitus Gallery")?.value)?.lead_id?.stage?.name}</h6>
                       <h6 onClick={()=>navigate('/leads/contacts/detail',{state:d?.fidelitusleadassigneds?.find((f)=>f?.department_id?._id === departments?.find((v)=>v.label == "Fidelitus Gallery")?.value)?.lead_id?._id})} className='text-[10px] cursor-pointer font-[500]  mt-1 underline text-blue-500'>View Details</h6>
                      </div> }

                      </h6> 
              </Tooltip>   

               <Tooltip disableInteractive title={(d?.fidelitusleadassigneds?.find((f)=>f?.department_id?._id === departments?.find((v)=>v.label == "Digital Media")?.value) !== null  && (d?.fidelitusleadassigneds?.find((f)=>f?.department_id?._id === departments?.find((v)=>v.label == "Digital Media")?.value)?.converted === '1' || d?.fidelitusleadassigneds?.find((f)=>f?.department_id?._id === departments?.find((v)=>v.label == "Digital Media")?.value)?.converted === '2')) &&   `Remarks : ${d?.fidelitusleadassigneds?.find((f)=>f?.department_id?._id === departments?.find((v)=>v.label == "Digital Media")?.value)?.remarks}`}>
                      <h6 className={`text-[12px] w-[120px]  px-2 py-1 font-[600] text-slate-600 border-r`}>

               {d?.fidelitusleadassigneds?.length > 0 ? 
                   (d?.fidelitusleadassigneds?.find((f)=>f?.department_id?._id === departments?.find((v)=>v.label == "Digital Media")?.value) !== null && d?.fidelitusleadassigneds?.find((f)=>f?.department_id?._id === departments?.find((v)=>v.label == "Digital Media")?.value) !== undefined)
                   ?      
                   d?.fidelitusleadassigneds?.find((f)=>f?.department_id?._id === departments?.find((v)=>v.label == "Digital Media")?.value)?.converted === '1' ?
                   <span className='flex items-center text-[11px] font-[700] mr-2'><h6 className='w-[10px] h-[10px] bg-green-400 mr-1'></h6> Converted</span> :
                   d?.fidelitusleadassigneds?.find((f)=>f?.department_id?._id === departments?.find((v)=>v.label == "Digital Media")?.value)?.converted === '2' ?
                   <span className='flex items-center text-[11px] font-[700] mr-2'><h6 className='w-[10px] h-[10px] bg-red-400 mr-1'></h6> Hold</span> :
                   d?.fidelitusleadassigneds?.find((f)=>f?.department_id?._id === departments?.find((v)=>v.label == "Digital Media")?.value)?.converted === '3' ?
                   <span className='flex items-center text-[11px] font-[700] mr-2'><h6 className='w-[10px] h-[10px] bg-yellow-400 mr-1'></h6> Pending</span> : 
                   d?.fidelitusleadassigneds?.find((f)=>f?.department_id?._id === departments?.find((v)=>v.label == "Digital Media")?.value)?.converted === '4' ?
                   <span className='flex items-center text-[11px] font-[700] mr-2'><h6 className='w-[10px] h-[10px] bg-blue-400 mr-1'></h6> Progress</span> : '' : '' : ''}
                     
                     {d?.fidelitusleadassigneds?.find((f)=>f?.department_id?._id === departments?.find((v)=>v.label == "Digital Media")?.value) !== undefined && <h6 className='text-[9px] text-black'>Assigned Date : {moment(d?.fidelitusleadassigneds?.find((f)=>f?.department_id?._id === departments?.find((v)=>v.label == "Digital Media")?.value)?.createdAt)?.format('D-M-YYYY')}</h6>}
                     {(d?.fidelitusleadassigneds?.find((f)=>f?.department_id?._id === departments?.find((v)=>v.label == "Digital Media")?.value)?.converted === '1' && d?.fidelitusleadassigneds?.find((f)=>f?.department_id?._id === departments?.find((v)=>v.label == "Digital Media")?.value)?.lead_id?._id !== undefined && d?.fidelitusleadassigneds?.find((f)=>f?.department_id?._id === departments?.find((v)=>v.label == "Digital Media")?.value)?.lead_id?._id !== null) && 
                      <div className='mt-1'>
                       <h6 className='text-[10px] font-[600] mt-1'>Lead Stage : {d?.fidelitusleadassigneds?.find((f)=>f?.department_id?._id === departments?.find((v)=>v.label == "Digital Media")?.value)?.lead_id?.stage?.name}</h6>
                       <h6 onClick={()=>navigate('/leads/contacts/detail',{state:d?.fidelitusleadassigneds?.find((f)=>f?.department_id?._id === departments?.find((v)=>v.label == "Digital Media")?.value)?.lead_id?._id})} className='text-[10px] cursor-pointer font-[500]  mt-1 underline text-blue-500'>View Details</h6>
                      </div> }

                      </h6> 
              </Tooltip>   

              <Tooltip disableInteractive title={(d?.fidelitusleadassigneds?.find((f)=>f?.department_id?._id === departments?.find((v)=>v.label == "Shilpa Foundation")?.value) !== null  && (d?.fidelitusleadassigneds?.find((f)=>f?.department_id?._id === departments?.find((v)=>v.label == "Shilpa Foundation")?.value)?.converted === '1' || d?.fidelitusleadassigneds?.find((f)=>f?.department_id?._id === departments?.find((v)=>v.label == "Shilpa Foundation")?.value)?.converted === '2')) &&   `Remarks : ${d?.fidelitusleadassigneds?.find((f)=>f?.department_id?._id === departments?.find((v)=>v.label == "Shilpa Foundation")?.value)?.remarks}`}>
                      <h6 className={`text-[12px] w-[120px]  px-2 py-1 font-[600] text-slate-600 border-r`}>

               {d?.fidelitusleadassigneds?.length > 0 ? 
                   (d?.fidelitusleadassigneds?.find((f)=>f?.department_id?._id === departments?.find((v)=>v.label == "Shilpa Foundation")?.value) !== null && d?.fidelitusleadassigneds?.find((f)=>f?.department_id?._id === departments?.find((v)=>v.label == "Shilpa Foundation")?.value) !== undefined)
                   ?      
                   d?.fidelitusleadassigneds?.find((f)=>f?.department_id?._id === departments?.find((v)=>v.label == "Shilpa Foundation")?.value)?.converted === '1' ?
                   <span className='flex items-center text-[11px] font-[700] mr-2'><h6 className='w-[10px] h-[10px] bg-green-400 mr-1'></h6> Converted</span> :
                   d?.fidelitusleadassigneds?.find((f)=>f?.department_id?._id === departments?.find((v)=>v.label == "Shilpa Foundation")?.value)?.converted === '2' ?
                   <span className='flex items-center text-[11px] font-[700] mr-2'><h6 className='w-[10px] h-[10px] bg-red-400 mr-1'></h6> Hold</span> :
                   d?.fidelitusleadassigneds?.find((f)=>f?.department_id?._id === departments?.find((v)=>v.label == "Shilpa Foundation")?.value)?.converted === '3' ?
                   <span className='flex items-center text-[11px] font-[700] mr-2'><h6 className='w-[10px] h-[10px] bg-yellow-400 mr-1'></h6> Pending</span> :
                   d?.fidelitusleadassigneds?.find((f)=>f?.department_id?._id === departments?.find((v)=>v.label == "Shilpa Foundation")?.value)?.converted === '4' ?
                   <span className='flex items-center text-[11px] font-[700] mr-2'><h6 className='w-[10px] h-[10px] bg-yellow-400 mr-1'></h6> Progress</span> : '' : '' : ''}
                     
                     {d?.fidelitusleadassigneds?.find((f)=>f?.department_id?._id === departments?.find((v)=>v.label == "Shilpa Foundation")?.value) !== undefined && <h6 className='text-[9px] text-black'>Assigned Date : {moment(d?.fidelitusleadassigneds?.find((f)=>f?.department_id?._id === departments?.find((v)=>v.label == "Shilpa Foundation")?.value)?.createdAt)?.format('D-M-YYYY')}</h6>}
                     {(d?.fidelitusleadassigneds?.find((f)=>f?.department_id?._id === departments?.find((v)=>v.label == "Shilpa Foundation")?.value)?.converted === '1' && d?.fidelitusleadassigneds?.find((f)=>f?.department_id?._id === departments?.find((v)=>v.label == "Shilpa Foundation")?.value)?.lead_id?._id !== undefined && d?.fidelitusleadassigneds?.find((f)=>f?.department_id?._id === departments?.find((v)=>v.label == "Shilpa Foundation")?.value)?.lead_id?._id !== null) && 
                      <div className='mt-1'>
                       <h6 className='text-[10px] font-[600] mt-1'>Lead Stage : {d?.fidelitusleadassigneds?.find((f)=>f?.department_id?._id === departments?.find((v)=>v.label == "Shilpa Foundation")?.value)?.lead_id?.stage?.name}</h6>
                       <h6 onClick={()=>navigate('/leads/contacts/detail',{state:d?.fidelitusleadassigneds?.find((f)=>f?.department_id?._id === departments?.find((v)=>v.label == "Shilpa Foundation")?.value)?.lead_id?._id})} className='text-[10px] cursor-pointer font-[500]  mt-1 underline text-blue-500'>View Details</h6>
                      </div> }

                      </h6> 
              </Tooltip>                         
             
              <h6 className='text-[12px]  font-[500] w-[120px]  px-2 py-1 truncate border-slate-200 '>
                 {d?.fidelitusleadassigneds?.length} / {d?.fidelitusleadassigneds?.filter((f)=>f?.converted === '1')?.length}
               
                 {roles?.includes('admin') &&
                 <AiOutlineEdit className='mt-1 ml-1' onClick={()=>navigate('/assign_leads/list/edit',{state:d?._id})} />}
                  </h6>
          </div>))}
          </div>}
          </>}

          {step === 2 &&
          <>

           <h6 className='bg-slate-700 p-1 px-2 text-[12px] text-white font-[800]'>Assign / Convert Ratio</h6>


            <div className='grid grid-cols-4 gap-2 my-2'>
              <div onClick={()=>openVerticalData()} className='border p-2 '>
                 <h6 className='text-[11px] font-[600]'>Transaction Team</h6>
                 
                 <div className='flex border justify-evenly mt-1'>
                    <h6 className='w-[20%] border-r p-[4px] text-[11px]'>Leads</h6>
                    <h6 className='w-[20%] border-r p-[4px] text-[11px]'>Pending</h6>
                    <h6 className='w-[20%] border-r p-[4px] text-[11px]'>Progress</h6>
                    <h6 className='w-[20%] border-r p-[4px] text-[11px]'>Rejected</h6>
                    <h6 className='w-[20%] p-[4px] text-[11px]'>Converted</h6>
                 </div>

                 <div className='flex border-l border-b border-r justify-evenly'>
                    <h6 className='w-[20%] border-r p-[4px] text-[11px]'>{(graphData?.find((f)=>f?.department?.department_name == 'Transaction Team') !== null && graphData?.find((f)=>f?.department?.department_name == 'Transaction Team') !== undefined) ? graphData?.find((f)=>f?.department?.department_name == 'Transaction Team')?.leads : '0'}</h6>
                    <h6 className='w-[20%] border-r p-[4px] text-[11px]'>{(graphData?.find((f)=>f?.department?.department_name == 'Transaction Team') !== null && graphData?.find((f)=>f?.department?.department_name == 'Transaction Team') !== undefined) ? graphData?.find((f)=>f?.department?.department_name == 'Transaction Team')?.pending : '0'}</h6>
                    <h6 className='w-[20%] border-r p-[4px] text-[11px]'>{(graphData?.find((f)=>f?.department?.department_name == 'Transaction Team') !== null && graphData?.find((f)=>f?.department?.department_name == 'Transaction Team') !== undefined) ? graphData?.find((f)=>f?.department?.department_name == 'Transaction Team')?.progress : '0'}</h6>
                    <h6 className='w-[20%] border-r p-[4px] text-[11px]'>{(graphData?.find((f)=>f?.department?.department_name == 'Transaction Team') !== null && graphData?.find((f)=>f?.department?.department_name == 'Transaction Team') !== undefined) ? graphData?.find((f)=>f?.department?.department_name == 'Transaction Team')?.rejected : '0'}</h6>
                    <h6 className='w-[20%] p-[4px] text-[11px]'>{(graphData?.find((f)=>f?.department?.department_name == 'Transaction Team') !== null && graphData?.find((f)=>f?.department?.department_name == 'Transaction Team') !== undefined) ? graphData?.find((f)=>f?.department?.department_name == 'Transaction Team')?.converted : '0'}</h6>
                 </div>
              </div>  

              <div onClick={()=>openVerticalData()} className='border p-2 '>
                 <h6 className='text-[11px] font-[600]'>Project Team</h6>
                 
                 <div className='flex border justify-evenly mt-1'>
                    <h6 className='w-[20%] border-r p-[4px] text-[11px]'>Leads</h6>
                    <h6 className='w-[20%] border-r p-[4px] text-[11px]'>Pending</h6>
                    <h6 className='w-[20%] border-r p-[4px] text-[11px]'>Progress</h6>
                    <h6 className='w-[20%] border-r p-[4px] text-[11px]'>Rejected</h6>
                    <h6 className='w-[20%] p-[4px] text-[11px]'>Converted</h6>
                 </div>

                 <div className='flex border-l border-b border-r justify-evenly'>
                    <h6 className='w-[20%] border-r p-[4px] text-[11px]'>{(graphData?.find((f)=>f?.department?.department_name == 'Project Team') !== null && graphData?.find((f)=>f?.department?.department_name == 'Project Team') !== undefined) ? graphData?.find((f)=>f?.department?.department_name == 'Project Team')?.leads : '0'}</h6>
                    <h6 className='w-[20%] border-r p-[4px] text-[11px]'>{(graphData?.find((f)=>f?.department?.department_name == 'Project Team') !== null && graphData?.find((f)=>f?.department?.department_name == 'Project Team') !== undefined) ? graphData?.find((f)=>f?.department?.department_name == 'Project Team')?.pending : '0'}</h6>
                    <h6 className='w-[20%] border-r p-[4px] text-[11px]'>{(graphData?.find((f)=>f?.department?.department_name == 'Project Team') !== null && graphData?.find((f)=>f?.department?.department_name == 'Project Team') !== undefined) ? graphData?.find((f)=>f?.department?.department_name == 'Project Team')?.progress : '0'}</h6>
                    <h6 className='w-[20%] border-r p-[4px] text-[11px]'>{(graphData?.find((f)=>f?.department?.department_name == 'Project Team') !== null && graphData?.find((f)=>f?.department?.department_name == 'Project Team') !== undefined) ? graphData?.find((f)=>f?.department?.department_name == 'Project Team')?.rejected : '0'}</h6>
                    <h6 className='w-[20%] p-[4px] text-[11px]'>{(graphData?.find((f)=>f?.department?.department_name == 'Project Team') !== null && graphData?.find((f)=>f?.department?.department_name == 'Project Team') !== undefined) ? graphData?.find((f)=>f?.department?.department_name == 'Project Team')?.converted : '0'}</h6>
                 </div>
              </div>  

              <div onClick={()=>openVerticalData()} className='border p-2 '>
                 <h6 className='text-[11px] font-[600]'>HR Team</h6>
                 
                 <div className='flex border justify-evenly mt-1'>
                    <h6 className='w-[20%] border-r p-[4px] text-[11px]'>Leads</h6>
                    <h6 className='w-[20%] border-r p-[4px] text-[11px]'>Pending</h6>
                    <h6 className='w-[20%] border-r p-[4px] text-[11px]'>Progress</h6>
                    <h6 className='w-[20%] border-r p-[4px] text-[11px]'>Rejected</h6>
                    <h6 className='w-[20%] p-[4px] text-[11px]'>Converted</h6>
                 </div>

                 <div className='flex border-l border-b border-r justify-evenly'>
                    <h6 className='w-[20%] border-r p-[4px] text-[11px]'>{(graphData?.find((f)=>f?.department?.department_name == 'HR Team') !== null && graphData?.find((f)=>f?.department?.department_name == 'HR Team') !== undefined) ? graphData?.find((f)=>f?.department?.department_name == 'HR Team')?.leads : '0'}</h6>
                    <h6 className='w-[20%] border-r p-[4px] text-[11px]'>{(graphData?.find((f)=>f?.department?.department_name == 'HR Team') !== null && graphData?.find((f)=>f?.department?.department_name == 'HR Team') !== undefined) ? graphData?.find((f)=>f?.department?.department_name == 'HR Team')?.pending : '0'}</h6>
                    <h6 className='w-[20%] border-r p-[4px] text-[11px]'>{(graphData?.find((f)=>f?.department?.department_name == 'HR Team') !== null && graphData?.find((f)=>f?.department?.department_name == 'HR Team') !== undefined) ? graphData?.find((f)=>f?.department?.department_name == 'HR Team')?.progress : '0'}</h6>
                    <h6 className='w-[20%] border-r p-[4px] text-[11px]'>{(graphData?.find((f)=>f?.department?.department_name == 'HR Team') !== null && graphData?.find((f)=>f?.department?.department_name == 'HR Team') !== undefined) ? graphData?.find((f)=>f?.department?.department_name == 'HR Team')?.rejected : '0'}</h6>
                    <h6 className='w-[20%] p-[4px] text-[11px]'>{(graphData?.find((f)=>f?.department?.department_name == 'HR Team') !== null && graphData?.find((f)=>f?.department?.department_name == 'HR Team') !== undefined) ? graphData?.find((f)=>f?.department?.department_name == 'HR Team')?.converted : '0'}</h6>
                 </div>
              </div>  

              <div onClick={()=>openVerticalData()} className='border p-2 '>
                 <h6 className='text-[11px] font-[600]'>FMS Team</h6>
                 
                 <div className='flex border justify-evenly mt-1'>
                    <h6 className='w-[20%] border-r p-[4px] text-[11px]'>Leads</h6>
                    <h6 className='w-[20%] border-r p-[4px] text-[11px]'>Pending</h6>
                    <h6 className='w-[20%] border-r p-[4px] text-[11px]'>Progress</h6>
                    <h6 className='w-[20%] border-r p-[4px] text-[11px]'>Rejected</h6>
                    <h6 className='w-[20%] p-[4px] text-[11px]'>Converted</h6>
                 </div>

                 <div className='flex border-l border-b border-r justify-evenly'>
                    <h6 className='w-[20%] border-r p-[4px] text-[11px]'>{(graphData?.find((f)=>f?.department?.department_name == 'FMS Team') !== null && graphData?.find((f)=>f?.department?.department_name == 'FMS Team') !== undefined) ? graphData?.find((f)=>f?.department?.department_name == 'FMS Team')?.leads : '0'}</h6>
                    <h6 className='w-[20%] border-r p-[4px] text-[11px]'>{(graphData?.find((f)=>f?.department?.department_name == 'FMS Team') !== null && graphData?.find((f)=>f?.department?.department_name == 'FMS Team') !== undefined) ? graphData?.find((f)=>f?.department?.department_name == 'FMS Team')?.pending : '0'}</h6>
                    <h6 className='w-[20%] border-r p-[4px] text-[11px]'>{(graphData?.find((f)=>f?.department?.department_name == 'FMS Team') !== null && graphData?.find((f)=>f?.department?.department_name == 'FMS Team') !== undefined) ? graphData?.find((f)=>f?.department?.department_name == 'FMS Team')?.progress : '0'}</h6>
                    <h6 className='w-[20%] border-r p-[4px] text-[11px]'>{(graphData?.find((f)=>f?.department?.department_name == 'FMS Team') !== null && graphData?.find((f)=>f?.department?.department_name == 'FMS Team') !== undefined) ? graphData?.find((f)=>f?.department?.department_name == 'FMS Team')?.rejected : '0'}</h6>
                    <h6 className='w-[20%] p-[4px] text-[11px]'>{(graphData?.find((f)=>f?.department?.department_name == 'FMS Team') !== null && graphData?.find((f)=>f?.department?.department_name == 'FMS Team') !== undefined) ? graphData?.find((f)=>f?.department?.department_name == 'FMS Team')?.converted : '0'}</h6>
                 </div>
              </div>  

              <div onClick={()=>openVerticalData()} className='border p-2 '>
                 <h6 className='text-[11px] font-[600]'>Fidelitus Gallery</h6>
                 
                 <div className='flex border justify-evenly mt-1'>
                    <h6 className='w-[20%] border-r p-[4px] text-[11px]'>Leads</h6>
                    <h6 className='w-[20%] border-r p-[4px] text-[11px]'>Pending</h6>
                    <h6 className='w-[20%] border-r p-[4px] text-[11px]'>Progress</h6>
                    <h6 className='w-[20%] border-r p-[4px] text-[11px]'>Rejected</h6>
                    <h6 className='w-[20%] p-[4px] text-[11px]'>Converted</h6>
                 </div>

                 <div className='flex border-l border-b border-r justify-evenly'>
                    <h6 className='w-[20%] border-r p-[4px] text-[11px]'>{(graphData?.find((f)=>f?.department?.department_name == 'Fidelitus Gallery') !== null && graphData?.find((f)=>f?.department?.department_name == 'Fidelitus Gallery') !== undefined) ? graphData?.find((f)=>f?.department?.department_name == 'Fidelitus Gallery')?.leads : '0'}</h6>
                    <h6 className='w-[20%] border-r p-[4px] text-[11px]'>{(graphData?.find((f)=>f?.department?.department_name == 'Fidelitus Gallery') !== null && graphData?.find((f)=>f?.department?.department_name == 'Fidelitus Gallery') !== undefined) ? graphData?.find((f)=>f?.department?.department_name == 'Fidelitus Gallery')?.pending : '0'}</h6>
                    <h6 className='w-[20%] border-r p-[4px] text-[11px]'>{(graphData?.find((f)=>f?.department?.department_name == 'Fidelitus Gallery') !== null && graphData?.find((f)=>f?.department?.department_name == 'Fidelitus Gallery') !== undefined) ? graphData?.find((f)=>f?.department?.department_name == 'Fidelitus Gallery')?.progress : '0'}</h6>
                    <h6 className='w-[20%] border-r p-[4px] text-[11px]'>{(graphData?.find((f)=>f?.department?.department_name == 'Fidelitus Gallery') !== null && graphData?.find((f)=>f?.department?.department_name == 'Fidelitus Gallery') !== undefined) ? graphData?.find((f)=>f?.department?.department_name == 'Fidelitus Gallery')?.rejected : '0'}</h6>
                    <h6 className='w-[20%] p-[4px] text-[11px]'>{(graphData?.find((f)=>f?.department?.department_name == 'Fidelitus Gallery') !== null && graphData?.find((f)=>f?.department?.department_name == 'Fidelitus Gallery') !== undefined) ? graphData?.find((f)=>f?.department?.department_name == 'Fidelitus Gallery')?.converted : '0'}</h6>
                 </div>
              </div> 

              <div onClick={()=>openVerticalData()} className='border p-2 '>
                 <h6 className='text-[11px] font-[600]'>Shilpa Foundation</h6>
                 
                 <div className='flex border justify-evenly mt-1'>
                    <h6 className='w-[20%] border-r p-[4px] text-[11px]'>Leads</h6>
                    <h6 className='w-[20%] border-r p-[4px] text-[11px]'>Pending</h6>
                    <h6 className='w-[20%] border-r p-[4px] text-[11px]'>Progress</h6>
                    <h6 className='w-[20%] border-r p-[4px] text-[11px]'>Rejected</h6>
                    <h6 className='w-[20%] p-[4px] text-[11px]'>Converted</h6>
                 </div>

                 <div className='flex border-l border-b border-r justify-evenly'>
                    <h6 className='w-[20%] border-r p-[4px] text-[11px]'>{(graphData?.find((f)=>f?.department?.department_name == 'Shilpa Foundation') !== null && graphData?.find((f)=>f?.department?.department_name == 'Shilpa Foundation') !== undefined) ? graphData?.find((f)=>f?.department?.department_name == 'Shilpa Foundation')?.leads : '0'}</h6>
                    <h6 className='w-[20%] border-r p-[4px] text-[11px]'>{(graphData?.find((f)=>f?.department?.department_name == 'Shilpa Foundation') !== null && graphData?.find((f)=>f?.department?.department_name == 'Shilpa Foundation') !== undefined) ? graphData?.find((f)=>f?.department?.department_name == 'Shilpa Foundation')?.pending : '0'}</h6>
                    <h6 className='w-[20%] border-r p-[4px] text-[11px]'>{(graphData?.find((f)=>f?.department?.department_name == 'Shilpa Foundation') !== null && graphData?.find((f)=>f?.department?.department_name == 'Shilpa Foundation') !== undefined) ? graphData?.find((f)=>f?.department?.department_name == 'Shilpa Foundation')?.progress : '0'}</h6>
                    <h6 className='w-[20%] border-r p-[4px] text-[11px]'>{(graphData?.find((f)=>f?.department?.department_name == 'Shilpa Foundation') !== null && graphData?.find((f)=>f?.department?.department_name == 'Shilpa Foundation') !== undefined) ? graphData?.find((f)=>f?.department?.department_name == 'Shilpa Foundation')?.rejected : '0'}</h6>
                    <h6 className='w-[20%] p-[4px] text-[11px]'>{(graphData?.find((f)=>f?.department?.department_name == 'Shilpa Foundation') !== null && graphData?.find((f)=>f?.department?.department_name == 'Shilpa Foundation') !== undefined) ? graphData?.find((f)=>f?.department?.department_name == 'Shilpa Foundation')?.converted : '0'}</h6>
                 </div>
              </div> 

              <div onClick={()=>openVerticalData()} className='border p-2 '>
                 <h6 className='text-[11px] font-[600]'>Media Team</h6>
                 
                 <div className='flex border justify-evenly mt-1'>
                    <h6 className='w-[20%] border-r p-[4px] text-[11px]'>Leads</h6>
                    <h6 className='w-[20%] border-r p-[4px] text-[11px]'>Pending</h6>
                    <h6 className='w-[20%] border-r p-[4px] text-[11px]'>Progress</h6>
                    <h6 className='w-[20%] border-r p-[4px] text-[11px]'>Rejected</h6>
                    <h6 className='w-[20%] p-[4px] text-[11px]'>Converted</h6>
                 </div>

                 <div className='flex border-l border-b border-r justify-evenly'>
                    <h6 className='w-[20%] border-r p-[4px] text-[11px]'>{(graphData?.find((f)=>f?.department?.department_name == 'Media Team') !== null && graphData?.find((f)=>f?.department?.department_name == 'Media Team') !== undefined) ? graphData?.find((f)=>f?.department?.department_name == 'Media Team')?.leads : '0'}</h6>
                    <h6 className='w-[20%] border-r p-[4px] text-[11px]'>{(graphData?.find((f)=>f?.department?.department_name == 'Media Team') !== null && graphData?.find((f)=>f?.department?.department_name == 'Media Team') !== undefined) ? graphData?.find((f)=>f?.department?.department_name == 'Media Team')?.pending : '0'}</h6>
                    <h6 className='w-[20%] border-r p-[4px] text-[11px]'>{(graphData?.find((f)=>f?.department?.department_name == 'Media Team') !== null && graphData?.find((f)=>f?.department?.department_name == 'Media Team') !== undefined) ? graphData?.find((f)=>f?.department?.department_name == 'Media Team')?.progress : '0'}</h6>
                    <h6 className='w-[20%] border-r p-[4px] text-[11px]'>{(graphData?.find((f)=>f?.department?.department_name == 'Media Team') !== null && graphData?.find((f)=>f?.department?.department_name == 'Media Team') !== undefined) ? graphData?.find((f)=>f?.department?.department_name == 'Media Team')?.rejected : '0'}</h6>
                    <h6 className='w-[20%] p-[4px] text-[11px]'>{(graphData?.find((f)=>f?.department?.department_name == 'Media Team') !== null && graphData?.find((f)=>f?.department?.department_name == 'Media Team') !== undefined) ? graphData?.find((f)=>f?.department?.department_name == 'Media Team')?.converted : '0'}</h6>
                 </div>
              </div> 

              <div onClick={()=>openVerticalData()} className='border p-2 '>
                 <h6 className='text-[11px] font-[600]'>Corp Team</h6>
                 
                 <div className='flex border justify-evenly mt-1'>
                    <h6 className='w-[20%] border-r p-[4px] text-[11px]'>Leads</h6>
                    <h6 className='w-[20%] border-r p-[4px] text-[11px]'>Pending</h6>
                    <h6 className='w-[20%] border-r p-[4px] text-[11px]'>Progress</h6>
                    <h6 className='w-[20%] border-r p-[4px] text-[11px]'>Rejected</h6>
                    <h6 className='w-[20%] p-[4px] text-[11px]'>Converted</h6>
                 </div>

                 <div className='flex border-l border-b border-r justify-evenly'>
                    <h6 className='w-[20%] border-r p-[4px] text-[11px]'>{(graphData?.find((f)=>f?.department?.department_name == 'Corp Team') !== null && graphData?.find((f)=>f?.department?.department_name == 'Corp Team') !== undefined) ? graphData?.find((f)=>f?.department?.department_name == 'Corp Team')?.leads : '0'}</h6>
                    <h6 className='w-[20%] border-r p-[4px] text-[11px]'>{(graphData?.find((f)=>f?.department?.department_name == 'Corp Team') !== null && graphData?.find((f)=>f?.department?.department_name == 'Corp Team') !== undefined) ? graphData?.find((f)=>f?.department?.department_name == 'Corp Team')?.pending : '0'}</h6>
                    <h6 className='w-[20%] border-r p-[4px] text-[11px]'>{(graphData?.find((f)=>f?.department?.department_name == 'Corp Team') !== null && graphData?.find((f)=>f?.department?.department_name == 'Corp Team') !== undefined) ? graphData?.find((f)=>f?.department?.department_name == 'Corp Team')?.progress : '0'}</h6>
                    <h6 className='w-[20%] border-r p-[4px] text-[11px]'>{(graphData?.find((f)=>f?.department?.department_name == 'Corp Team') !== null && graphData?.find((f)=>f?.department?.department_name == 'Corp Team') !== undefined) ? graphData?.find((f)=>f?.department?.department_name == 'Corp Team')?.rejected : '0'}</h6>
                    <h6 className='w-[20%] p-[4px] text-[11px]'>{(graphData?.find((f)=>f?.department?.department_name == 'Corp Team') !== null && graphData?.find((f)=>f?.department?.department_name == 'Corp Team') !== undefined) ? graphData?.find((f)=>f?.department?.department_name == 'Corp Team')?.converted : '0'}</h6>
                 </div>
              </div> 
            </div>


            <h6 className='bg-slate-700 p-1 px-2 text-[12px] mb-2 text-white font-[800]'>Graph Ratio</h6>
            {leads?.datas?.length > 0 && 
            <div className='max-h-[85vh] border p-4 overflow-y-scroll'>

              <h6 className='text-[13px] font-[700]'>Vertical Wise Report {(search?.from_date && search?.to_date) &&  `From Date (${moment(search?.from_date)?.format('LL')} - ${moment(search?.to_date)?.format('LL')})`}</h6>
          
              <ReactApexChart options={chartOptions1} size={'10'} series={chartOptions1.series} type="bar" width={'100%'}  height={240} />

            </div>}
          </>}

          {step === 3 &&
          <div className='flex'>
            
            <div className='w-[27%] overflow-y-scroll h-[85vh] pr-2'>

              
              <div className='flex justify-between'>
                 
                <h6 className='text-[12px] font-[700]'>{monthNames[calendar_report?.month]} - {calendar_report?.year}</h6>

                <div className='flex'>
                   <HiChevronLeft className='mx-1 text-slate-400' onClick={()=>resetmonth(0)} />
                   <HiChevronRight className='mx-1 text-slate-400' onClick={()=>resetmonth(1)} />
                </div>  

                {/* <h6>{calendar_report.no_of_days}</h6> */}
              
              </div>
              <div className='grid grid-cols-7 border-t border-l  my-2'>
                {weekDays?.map((d,i)=>(
                  <h6 className={`text-[11px] border-b ${![6,13,20,27,34]?.includes(i) && 'border-r'} border-r p-2 text-slate-500 text-center uppercase font-[700]`}>{d?.slice(0,1)}</h6>
                ))}

                {new Array(31 + calendar_report.start_date_index)?.fill('-')?.map((e,i)=>(
                    <div className={`min-w-[100%] border-b ${![6,13,20,27,34]?.includes(i) && 'border-r'} border-r cursor-pointer flex items-center justify-center max-w-[100%]`}>
                      <h6 className={`text-[10px] p-2 h-[30px] font-[700] text-center text-gray-800 w-[100%] ${i >= calendar_report.start_date_index ? 'opacity-100' : 'opacity-0'} `}>{(i >= calendar_report.start_date_index && ((i + 1) - calendar_report.start_date_index) <= calendar_report.no_of_days ) &&    (i + 1) - calendar_report.start_date_index}</h6>
                    </div>  
                  ))}

              </div>  


              <h6 className='bg-slate-700 p-1 px-2 text-[12px] text-white font-[800]'>Assign / Convert Ratio</h6>

              <div className='border p-2 mt-2'>
                 <h6 className='text-[11px] font-[600]'>Transaction Team</h6>
                 
                 <div className='flex border justify-evenly mt-1'>
                    <h6 className='w-[20%] border-r p-[4px] text-[11px]'>Leads</h6>
                    <h6 className='w-[20%] border-r p-[4px] text-[11px]'>Pending</h6>
                    <h6 className='w-[20%] border-r p-[4px] text-[11px]'>Progress</h6>
                    <h6 className='w-[20%] border-r p-[4px] text-[11px]'>Rejected</h6>
                    <h6 className='w-[20%] p-[4px] text-[11px]'>Converted</h6>
                 </div>

                 <div className='flex border-l border-b border-r justify-evenly'>
                    <h6 className='w-[20%] border-r p-[4px] text-[11px]'>{(calendarData?.count?.find((f)=>f?.department?.department_name == 'Transaction Team') !== null && calendarData?.count?.find((f)=>f?.department?.department_name == 'Transaction Team') !== undefined) ? calendarData?.count?.find((f)=>f?.department?.department_name == 'Transaction Team')?.leads : '0'}</h6>
                    <h6 className='w-[20%] border-r p-[4px] text-[11px]'>{(calendarData?.count?.find((f)=>f?.department?.department_name == 'Transaction Team') !== null && calendarData?.count?.find((f)=>f?.department?.department_name == 'Transaction Team') !== undefined) ? calendarData?.count?.find((f)=>f?.department?.department_name == 'Transaction Team')?.pending : '0'}</h6>
                    <h6 className='w-[20%] border-r p-[4px] text-[11px]'>{(calendarData?.count?.find((f)=>f?.department?.department_name == 'Transaction Team') !== null && calendarData?.count?.find((f)=>f?.department?.department_name == 'Transaction Team') !== undefined) ? calendarData?.count?.find((f)=>f?.department?.department_name == 'Transaction Team')?.progress : '0'}</h6>
                    <h6 className='w-[20%] border-r p-[4px] text-[11px]'>{(calendarData?.count?.find((f)=>f?.department?.department_name == 'Transaction Team') !== null && calendarData?.count?.find((f)=>f?.department?.department_name == 'Transaction Team') !== undefined) ? calendarData?.count?.find((f)=>f?.department?.department_name == 'Transaction Team')?.rejected : '0'}</h6>
                    <h6 className='w-[20%] p-[4px] text-[11px]'>{(calendarData?.count?.find((f)=>f?.department?.department_name == 'Transaction Team') !== null && calendarData?.count?.find((f)=>f?.department?.department_name == 'Transaction Team') !== undefined) ? calendarData?.count?.find((f)=>f?.department?.department_name == 'Transaction Team')?.converted : '0'}</h6>
                 </div>
              </div>  

              <div className='border p-2 mt-2'>
                 <h6 className='text-[11px] font-[600]'>Project Team</h6>
                 
                 <div className='flex border justify-evenly mt-1'>
                    <h6 className='w-[20%] border-r p-[4px] text-[11px]'>Leads</h6>
                    <h6 className='w-[20%] border-r p-[4px] text-[11px]'>Pending</h6>
                    <h6 className='w-[20%] border-r p-[4px] text-[11px]'>Progress</h6>
                    <h6 className='w-[20%] border-r p-[4px] text-[11px]'>Rejected</h6>
                    <h6 className='w-[20%] p-[4px] text-[11px]'>Converted</h6>
                 </div>

                 <div className='flex border-l border-b border-r justify-evenly'>
                    <h6 className='w-[20%] border-r p-[4px] text-[11px]'>{(calendarData?.count?.find((f)=>f?.department?.department_name == 'Project Team') !== null && calendarData?.count?.find((f)=>f?.department?.department_name == 'Project Team') !== undefined) ? calendarData?.count?.find((f)=>f?.department?.department_name == 'Project Team')?.leads : '0'}</h6>
                    <h6 className='w-[20%] border-r p-[4px] text-[11px]'>{(calendarData?.count?.find((f)=>f?.department?.department_name == 'Project Team') !== null && calendarData?.count?.find((f)=>f?.department?.department_name == 'Project Team') !== undefined) ? calendarData?.count?.find((f)=>f?.department?.department_name == 'Project Team')?.pending : '0'}</h6>
                    <h6 className='w-[20%] border-r p-[4px] text-[11px]'>{(calendarData?.count?.find((f)=>f?.department?.department_name == 'Project Team') !== null && calendarData?.count?.find((f)=>f?.department?.department_name == 'Project Team') !== undefined) ? calendarData?.count?.find((f)=>f?.department?.department_name == 'Project Team')?.progress : '0'}</h6>
                    <h6 className='w-[20%] border-r p-[4px] text-[11px]'>{(calendarData?.count?.find((f)=>f?.department?.department_name == 'Project Team') !== null && calendarData?.count?.find((f)=>f?.department?.department_name == 'Project Team') !== undefined) ? calendarData?.count?.find((f)=>f?.department?.department_name == 'Project Team')?.rejected : '0'}</h6>
                    <h6 className='w-[20%] p-[4px] text-[11px]'>{(calendarData?.count?.find((f)=>f?.department?.department_name == 'Project Team') !== null && calendarData?.count?.find((f)=>f?.department?.department_name == 'Project Team') !== undefined) ? calendarData?.count?.find((f)=>f?.department?.department_name == 'Project Team')?.converted : '0'}</h6>
                 </div>
              </div>  

              <div className='border p-2 mt-2'>
                 <h6 className='text-[11px] font-[600]'>HR Team</h6>
                 
                 <div className='flex border justify-evenly mt-1'>
                    <h6 className='w-[20%] border-r p-[4px] text-[11px]'>Leads</h6>
                    <h6 className='w-[20%] border-r p-[4px] text-[11px]'>Pending</h6>
                    <h6 className='w-[20%] border-r p-[4px] text-[11px]'>Progress</h6>
                    <h6 className='w-[20%] border-r p-[4px] text-[11px]'>Rejected</h6>
                    <h6 className='w-[20%] p-[4px] text-[11px]'>Converted</h6>
                 </div>

                 <div className='flex border-l border-b border-r justify-evenly'>
                    <h6 className='w-[20%] border-r p-[4px] text-[11px]'>{(calendarData?.count?.find((f)=>f?.department?.department_name == 'HR Team') !== null && calendarData?.count?.find((f)=>f?.department?.department_name == 'HR Team') !== undefined) ? calendarData?.count?.find((f)=>f?.department?.department_name == 'HR Team')?.leads : '0'}</h6>
                    <h6 className='w-[20%] border-r p-[4px] text-[11px]'>{(calendarData?.count?.find((f)=>f?.department?.department_name == 'HR Team') !== null && calendarData?.count?.find((f)=>f?.department?.department_name == 'HR Team') !== undefined) ? calendarData?.count?.find((f)=>f?.department?.department_name == 'HR Team')?.pending : '0'}</h6>
                    <h6 className='w-[20%] border-r p-[4px] text-[11px]'>{(calendarData?.count?.find((f)=>f?.department?.department_name == 'HR Team') !== null && calendarData?.count?.find((f)=>f?.department?.department_name == 'HR Team') !== undefined) ? calendarData?.count?.find((f)=>f?.department?.department_name == 'HR Team')?.progress : '0'}</h6>
                    <h6 className='w-[20%] border-r p-[4px] text-[11px]'>{(calendarData?.count?.find((f)=>f?.department?.department_name == 'HR Team') !== null && calendarData?.count?.find((f)=>f?.department?.department_name == 'HR Team') !== undefined) ? calendarData?.count?.find((f)=>f?.department?.department_name == 'HR Team')?.rejected : '0'}</h6>
                    <h6 className='w-[20%] p-[4px] text-[11px]'>{(calendarData?.count?.find((f)=>f?.department?.department_name == 'HR Team') !== null && calendarData?.count?.find((f)=>f?.department?.department_name == 'HR Team') !== undefined) ? calendarData?.count?.find((f)=>f?.department?.department_name == 'HR Team')?.converted : '0'}</h6>
                 </div>
              </div>  

              <div className='border p-2 mt-2'>
                 <h6 className='text-[11px] font-[600]'>FMS Team</h6>
                 
                 <div className='flex border justify-evenly mt-1'>
                    <h6 className='w-[20%] border-r p-[4px] text-[11px]'>Leads</h6>
                    <h6 className='w-[20%] border-r p-[4px] text-[11px]'>Pending</h6>
                    <h6 className='w-[20%] border-r p-[4px] text-[11px]'>Progress</h6>
                    <h6 className='w-[20%] border-r p-[4px] text-[11px]'>Rejected</h6>
                    <h6 className='w-[20%] p-[4px] text-[11px]'>Converted</h6>
                 </div>

                 <div className='flex border-l border-b border-r justify-evenly'>
                    <h6 className='w-[20%] border-r p-[4px] text-[11px]'>{(calendarData?.count?.find((f)=>f?.department?.department_name == 'FMS Team') !== null && calendarData?.count?.find((f)=>f?.department?.department_name == 'FMS Team') !== undefined) ? calendarData?.count?.find((f)=>f?.department?.department_name == 'FMS Team')?.leads : '0'}</h6>
                    <h6 className='w-[20%] border-r p-[4px] text-[11px]'>{(calendarData?.count?.find((f)=>f?.department?.department_name == 'FMS Team') !== null && calendarData?.count?.find((f)=>f?.department?.department_name == 'FMS Team') !== undefined) ? calendarData?.count?.find((f)=>f?.department?.department_name == 'FMS Team')?.pending : '0'}</h6>
                    <h6 className='w-[20%] border-r p-[4px] text-[11px]'>{(calendarData?.count?.find((f)=>f?.department?.department_name == 'FMS Team') !== null && calendarData?.count?.find((f)=>f?.department?.department_name == 'FMS Team') !== undefined) ? calendarData?.count?.find((f)=>f?.department?.department_name == 'FMS Team')?.progress : '0'}</h6>
                    <h6 className='w-[20%] border-r p-[4px] text-[11px]'>{(calendarData?.count?.find((f)=>f?.department?.department_name == 'FMS Team') !== null && calendarData?.count?.find((f)=>f?.department?.department_name == 'FMS Team') !== undefined) ? calendarData?.count?.find((f)=>f?.department?.department_name == 'FMS Team')?.rejected : '0'}</h6>
                    <h6 className='w-[20%] p-[4px] text-[11px]'>{(calendarData?.count?.find((f)=>f?.department?.department_name == 'FMS Team') !== null && calendarData?.count?.find((f)=>f?.department?.department_name == 'FMS Team') !== undefined) ? calendarData?.count?.find((f)=>f?.department?.department_name == 'FMS Team')?.converted : '0'}</h6>
                 </div>
              </div>  

              <div className='border p-2 mt-2'>
                 <h6 className='text-[11px] font-[600]'>Fidelitus Gallery</h6>
                 
                 <div className='flex border justify-evenly mt-1'>
                    <h6 className='w-[20%] border-r p-[4px] text-[11px]'>Leads</h6>
                    <h6 className='w-[20%] border-r p-[4px] text-[11px]'>Pending</h6>
                    <h6 className='w-[20%] border-r p-[4px] text-[11px]'>Progress</h6>
                    <h6 className='w-[20%] border-r p-[4px] text-[11px]'>Rejected</h6>
                    <h6 className='w-[20%] p-[4px] text-[11px]'>Converted</h6>
                 </div>

                 <div className='flex border-l border-b border-r justify-evenly'>
                    <h6 className='w-[20%] border-r p-[4px] text-[11px]'>{(calendarData?.count?.find((f)=>f?.department?.department_name == 'Fidelitus Gallery') !== null && calendarData?.count?.find((f)=>f?.department?.department_name == 'Fidelitus Gallery') !== undefined) ? calendarData?.count?.find((f)=>f?.department?.department_name == 'Fidelitus Gallery')?.leads : '0'}</h6>
                    <h6 className='w-[20%] border-r p-[4px] text-[11px]'>{(calendarData?.count?.find((f)=>f?.department?.department_name == 'Fidelitus Gallery') !== null && calendarData?.count?.find((f)=>f?.department?.department_name == 'Fidelitus Gallery') !== undefined) ? calendarData?.count?.find((f)=>f?.department?.department_name == 'Fidelitus Gallery')?.pending : '0'}</h6>
                    <h6 className='w-[20%] border-r p-[4px] text-[11px]'>{(calendarData?.count?.find((f)=>f?.department?.department_name == 'Fidelitus Gallery') !== null && calendarData?.count?.find((f)=>f?.department?.department_name == 'Fidelitus Gallery') !== undefined) ? calendarData?.count?.find((f)=>f?.department?.department_name == 'Fidelitus Gallery')?.progress : '0'}</h6>
                    <h6 className='w-[20%] border-r p-[4px] text-[11px]'>{(calendarData?.count?.find((f)=>f?.department?.department_name == 'Fidelitus Gallery') !== null && calendarData?.count?.find((f)=>f?.department?.department_name == 'Fidelitus Gallery') !== undefined) ? calendarData?.count?.find((f)=>f?.department?.department_name == 'Fidelitus Gallery')?.rejected : '0'}</h6>
                    <h6 className='w-[20%] p-[4px] text-[11px]'>{(calendarData?.count?.find((f)=>f?.department?.department_name == 'Fidelitus Gallery') !== null && calendarData?.count?.find((f)=>f?.department?.department_name == 'Fidelitus Gallery') !== undefined) ? calendarData?.count?.find((f)=>f?.department?.department_name == 'Fidelitus Gallery')?.converted : '0'}</h6>
                 </div>
              </div>  

              <div className='border p-2 mt-2'>
                 <h6 className='text-[11px] font-[600]'>Digital Media</h6>
                 
                 <div className='flex border justify-evenly mt-1'>
                    <h6 className='w-[20%] border-r p-[4px] text-[11px]'>Leads</h6>
                    <h6 className='w-[20%] border-r p-[4px] text-[11px]'>Pending</h6>
                    <h6 className='w-[20%] border-r p-[4px] text-[11px]'>Progress</h6>
                    <h6 className='w-[20%] border-r p-[4px] text-[11px]'>Rejected</h6>
                    <h6 className='w-[20%] p-[4px] text-[11px]'>Converted</h6>
                 </div>

                 <div className='flex border-l border-b border-r justify-evenly'>
                    <h6 className='w-[20%] border-r p-[4px] text-[11px]'>{(calendarData?.count?.find((f)=>f?.department?.department_name == 'Digital Media') !== null && calendarData?.count?.find((f)=>f?.department?.department_name == 'Digital Media') !== undefined) ? calendarData?.count?.find((f)=>f?.department?.department_name == 'Digital Media')?.leads : '0'}</h6>
                    <h6 className='w-[20%] border-r p-[4px] text-[11px]'>{(calendarData?.count?.find((f)=>f?.department?.department_name == 'Digital Media') !== null && calendarData?.count?.find((f)=>f?.department?.department_name == 'Digital Media') !== undefined) ? calendarData?.count?.find((f)=>f?.department?.department_name == 'Digital Media')?.pending : '0'}</h6>
                    <h6 className='w-[20%] border-r p-[4px] text-[11px]'>{(calendarData?.count?.find((f)=>f?.department?.department_name == 'Digital Media') !== null && calendarData?.count?.find((f)=>f?.department?.department_name == 'Digital Media') !== undefined) ? calendarData?.count?.find((f)=>f?.department?.department_name == 'Digital Media')?.progress : '0'}</h6>
                    <h6 className='w-[20%] border-r p-[4px] text-[11px]'>{(calendarData?.count?.find((f)=>f?.department?.department_name == 'Digital Media') !== null && calendarData?.count?.find((f)=>f?.department?.department_name == 'Digital Media') !== undefined) ? calendarData?.count?.find((f)=>f?.department?.department_name == 'Digital Media')?.rejected : '0'}</h6>
                    <h6 className='w-[20%] p-[4px] text-[11px]'>{(calendarData?.count?.find((f)=>f?.department?.department_name == 'Digital Media') !== null && calendarData?.count?.find((f)=>f?.department?.department_name == 'Digital Media') !== undefined) ? calendarData?.count?.find((f)=>f?.department?.department_name == 'Digital Media')?.converted : '0'}</h6>
                 </div>
              </div>

               <div className='border p-2 mt-2'>
                 <h6 className='text-[11px] font-[600]'>Corp Team</h6>
                 
                 <div className='flex border justify-evenly mt-1'>
                    <h6 className='w-[20%] border-r p-[4px] text-[11px]'>Leads</h6>
                    <h6 className='w-[20%] border-r p-[4px] text-[11px]'>Pending</h6>
                    <h6 className='w-[20%] border-r p-[4px] text-[11px]'>Progress</h6>
                    <h6 className='w-[20%] border-r p-[4px] text-[11px]'>Rejected</h6>
                    <h6 className='w-[20%] p-[4px] text-[11px]'>Converted</h6>
                 </div>

                 <div className='flex border-l border-b border-r justify-evenly'>
                    <h6 className='w-[20%] border-r p-[4px] text-[11px]'>{(calendarData?.count?.find((f)=>f?.department?.department_name == 'Corp Team') !== null && calendarData?.count?.find((f)=>f?.department?.department_name == 'Corp Team') !== undefined) ? calendarData?.count?.find((f)=>f?.department?.department_name == 'Corp Team')?.leads : '0'}</h6>
                    <h6 className='w-[20%] border-r p-[4px] text-[11px]'>{(calendarData?.count?.find((f)=>f?.department?.department_name == 'Corp Team') !== null && calendarData?.count?.find((f)=>f?.department?.department_name == 'Corp Team') !== undefined) ? calendarData?.count?.find((f)=>f?.department?.department_name == 'Corp Team')?.pending : '0'}</h6>
                    <h6 className='w-[20%] border-r p-[4px] text-[11px]'>{(calendarData?.count?.find((f)=>f?.department?.department_name == 'Corp Team') !== null && calendarData?.count?.find((f)=>f?.department?.department_name == 'Corp Team') !== undefined) ? calendarData?.count?.find((f)=>f?.department?.department_name == 'Corp Team')?.progress : '0'}</h6>
                    <h6 className='w-[20%] border-r p-[4px] text-[11px]'>{(calendarData?.count?.find((f)=>f?.department?.department_name == 'Corp Team') !== null && calendarData?.count?.find((f)=>f?.department?.department_name == 'Corp Team') !== undefined) ? calendarData?.count?.find((f)=>f?.department?.department_name == 'Corp Team')?.rejected : '0'}</h6>
                    <h6 className='w-[100%] p-[4px] text-[11px]'>{(calendarData?.count?.find((f)=>f?.department?.department_name == 'Corp Team') !== null && calendarData?.count?.find((f)=>f?.department?.department_name == 'Corp Team') !== undefined) ? calendarData?.count?.find((f)=>f?.department?.department_name == 'Corp Team')?.converted : '0'}</h6>
                 </div>
              </div>  

              <div className='border p-2 mt-2'>
                 <h6 className='text-[11px] font-[600]'>Shilpa Foundation</h6>
                 
                 <div className='flex border justify-evenly mt-1'>
                    <h6 className='w-[20%] border-r p-[4px] text-[11px]'>Leads</h6>
                    <h6 className='w-[20%] border-r p-[4px] text-[11px]'>Pending</h6>
                    <h6 className='w-[20%] border-r p-[4px] text-[11px]'>Progress</h6>
                    <h6 className='w-[20%] border-r p-[4px] text-[11px]'>Rejected</h6>
                    <h6 className='w-[20%] p-[4px] text-[11px]'>Converted</h6>
                 </div>

                 <div className='flex border-l border-b border-r justify-evenly'>
                    <h6 className='w-[20%] border-r p-[4px] text-[11px]'>{(calendarData?.count?.find((f)=>f?.department?.department_name == 'Shilpa Foundation') !== null && calendarData?.count?.find((f)=>f?.department?.department_name == 'Shilpa Foundation') !== undefined) ? calendarData?.count?.find((f)=>f?.department?.department_name == 'Shilpa Foundation')?.leads : '0'}</h6>
                    <h6 className='w-[20%] border-r p-[4px] text-[11px]'>{(calendarData?.count?.find((f)=>f?.department?.department_name == 'Shilpa Foundation') !== null && calendarData?.count?.find((f)=>f?.department?.department_name == 'Shilpa Foundation') !== undefined) ? calendarData?.count?.find((f)=>f?.department?.department_name == 'Shilpa Foundation')?.pending : '0'}</h6>
                    <h6 className='w-[20%] border-r p-[4px] text-[11px]'>{(calendarData?.count?.find((f)=>f?.department?.department_name == 'Shilpa Foundation') !== null && calendarData?.count?.find((f)=>f?.department?.department_name == 'Shilpa Foundation') !== undefined) ? calendarData?.count?.find((f)=>f?.department?.department_name == 'Shilpa Foundation')?.progress : '0'}</h6>
                    <h6 className='w-[20%] border-r p-[4px] text-[11px]'>{(calendarData?.count?.find((f)=>f?.department?.department_name == 'Shilpa Foundation') !== null && calendarData?.count?.find((f)=>f?.department?.department_name == 'Shilpa Foundation') !== undefined) ? calendarData?.count?.find((f)=>f?.department?.department_name == 'Shilpa Foundation')?.rejected : '0'}</h6>
                    <h6 className='w-[20%] p-[4px] text-[11px]'>{(calendarData?.count?.find((f)=>f?.department?.department_name == 'Shilpa Foundation') !== null && calendarData?.count?.find((f)=>f?.department?.department_name == 'Shilpa Foundation') !== undefined) ? calendarData?.count?.find((f)=>f?.department?.department_name == 'Shilpa Foundation')?.converted : '0'}</h6>
                 </div>
              </div>  

            </div>

            <div className='w-[73%] h-[85vh]'>
                <div className='grid border-l border-r grid-cols-7 border'>
                  {weekDays?.map((d,i)=>(
                    <div className={`min-w-[100%] max-w-[100%] ${i !== (weekDays.length - 1) && 'border-r'}`}>
                    <h6 className='text-[12px] p-2 font-[700] text-center uppercase text-slate-800'>{d?.slice(0,3)}</h6>
                    </div>
                  ))}
                </div>

                  <div className='grid border-l border-r grid-cols-7 '>
                      {new Array(31 + calendar_report.start_date_index)?.fill('-')?.map((e,i)=>(
                        <div onClick={()=>i >= calendar_report.start_date_index &&   (i + 1) - calendar_report.start_date_index  &&  ((i - calendar_report?.start_date_index) < calendar_report?.no_of_days) && openSingleModalData(i >= calendar_report.start_date_index && (i + 1) - calendar_report.start_date_index)} className={`min-w-[100%] min-h-[85px] max-h-[85px] overflow-y-scroll cursor-pointer border-b relative max-w-[100%] ${![6,13,20,27,34]?.includes(i) && 'border-r'}`}>
                          
                          {returnCount((i + 1) - calendar_report.start_date_index) > 0 && 
                          <h6 className='bg-slate-700 text-white text-[11px] px-[5px] py-[2px] absolute right-0 top-0'>{returnCount((i + 1) - calendar_report.start_date_index)}</h6>
                          }
                          
                          <h6 className={`text-[10px] p-2 font-[700] text-center text-gray-800 w-[100%] ${i >= calendar_report.start_date_index ? 'opacity-100' : 'opacity-10'} `}>{i >= calendar_report.start_date_index && ((i - calendar_report?.start_date_index) < calendar_report?.no_of_days) && (i + 1) - calendar_report.start_date_index}</h6>
                          <div dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(returnData((i + 1) - calendar_report.start_date_index))}} className=' px-1 text-[11px]'>
                          </div>  
                        </div>  
                      ))}

            
                  </div>
            </div>

          </div>}

          
          {leads?.datas?.length === 0 &&
            <div className='grid place-items-center mt-20  items-center justify-center'>
            <img src={'https://fidecrmfiles.s3.amazonaws.com/NoDataFound1.png'} alt="no" className='w-40 h-40 object-contain' /> 
            <h6 className='font-bold text-[14px] -mt-10'>No data found</h6>
            <h6 className='font-[500] w-[70%] text-center text-slate-700 text-[12.5px] -mt-2'>Oops we couldn't find any data based on your current stage please add the data to see list here.</h6>
          </div>
          }


          </div>

        


        </div>

        
      
        </div>
        </div>
    </div>
    </div>
  )
}

export default FidelitusLeads